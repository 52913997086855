import { useEffect, useRef, useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import {  
    newGameVSPlayer, 
    rivalOfferedDraw, 
    setGameStarted,
    endGame,
    cancelGame,
    drawDeclined
} from '../store/gameStateSlice';
import {makeMove} from '../store/gameSlice'
import { IRootState } from '../models/models';
import { copyObj, isDev } from '../local-engine/gameplay-helper-fn';
import { sendWsMessage, setBackMessage, setConnectionState } from '../store/topStateSlice';
import { setToken } from '../store/userSlice';
import { wsService } from '../common/ws-service';


const mapState = (state: IRootState) => ({
    payload: state.topState.wsMessage,
    playerOnline: state.gameState.playerOnline,
    playerColor: state.gameState.playerColor,
    token: state.user.token,
    gameKey: state.gameState.gameKey,
})

const mapDispatch = {
    makeMove,
    newGameVSPlayer,
    sendMessage: sendWsMessage,
    rivalOfferedDraw, 
    setGameStarted,
    endGame,
    cancelGame,
    setConnectionState,
    drawDeclined,
    setBackMessage,
    setToken
}

let timeout = null as any
let messQueue = [] as any[]

const connector = connect(mapState, mapDispatch)
type Props = ConnectedProps<typeof connector>

const WebSocketClient = (props: Props) => {
    const tokenSend = useRef(false)
    const [ws, setWs] = useState(wsService.ws as WebSocket)
    // const [gameMode, setGameMode] = useState(modeService.mode)

    useEffect(() => {
        const unsubWs = wsService.$ws.subscribe(setWs)
        return () => {
            clearTimeout(timeout)
            unsubWs()
        }
    }, [])

    useEffect(() => {
        wsService.setToken(!!props.token)
    }, [props.token])

    useEffect(() => {
        // console.log('ws', ws?.readyState, tokenSend.current, props.token)
        if (!ws || ws?.readyState !== 1 || !ws.send) {
            props.setConnectionState(3 - (ws?.readyState || 3))
            tokenSend.current = false
            return
        }
        if (!tokenSend.current && props.token) {
            tokenSend.current = true
            // console.log('autorize')
            const mess = JSON.stringify({
                message: 'user authorized',
                payload: {token: props.token, gameKey: props.gameKey}
            })
            ws.send(mess)
        }
        if (messQueue.length) {
            for (const mess of messQueue) {
                if (mess.message !== 'user authorized') {
                    ws.send(JSON.stringify(mess))
                }
            }
            messQueue = []
        }
        props.setConnectionState(1)
        ws.onmessage = (event: any) => {
            console.log('Received message: ', event);
            messageHandler(event.data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws])
  
    useEffect(() => {
        isDev() && console.log('new mess', ws?.readyState, props.payload)
        if (!props.payload) return
        if (props.payload.message === 'signin' && props.token) {
            clearTimeout(timeout)
            messQueue.length = 0
            const mess = JSON.stringify({
                message: 'user authorized',
                payload: {token: props.token}
            })
            ws.send(mess)
            props.sendMessage(null)
            return
        }
        if (props.payload.message === 'logout') {
            messQueue.length = 0
            clearTimeout(timeout)
            if (ws && ws.readyState === 1) {
                ws.close()
            }
            props.sendMessage(null)
            return
        }
        if (!ws || ws?.readyState !== 1) {
            messQueue.push(props.payload)
            props.sendMessage(null)
            console.log(' mess queue', messQueue, )
            return
        }
        const payload = JSON.stringify(props.payload)
        ws.send(payload)
        isDev() && console.log('mess send', props.payload)
        props.sendMessage(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.payload])

    const messageHandler = (data: string) => {
        const {message, payload} = JSON.parse(data)
        console.log(message, copyObj(payload))
        if (payload === "error") {
            props.setBackMessage({msg: message, ok: false})
            return
        }
        switch (message) {
            case 'game move': {
                props.makeMove(payload)
                break
            }
            case 'new game': {
                props.newGameVSPlayer(payload)
                break
            }
            case 'game draw offered': {
                props.rivalOfferedDraw()
                break
            }
            case 'game draw declined': {
                props.drawDeclined()
                break
            }
            case 'game canceled': {
                props.cancelGame()
                break
            }
            case 'game ended': {
                if (!payload) {
                    console.error('invalid payload to end game', payload)
                }
                props.endGame(payload)
                break
            }
            case 'game started': {
                props.setGameStarted(true)
                props.setToken(payload)
                break
            }
            case 'opponent message': {
                break
            }
            case 'common chat message': {
                break
            }
            default: {
                break
            }
        }}
    return <></>
};

export default connector(WebSocketClient);
