import { useDispatch, useSelector } from "react-redux"
import { selectPuzzle, selectUserWithProps, selectWithProps } from "../../store/rootState&Reducer"
import GameBoard from "../Game/GameBoard"
import { useNavigate } from "react-router-dom"
import { PuzzleMenu } from "./PuzzleMenu"
import { choosePuzzle, selectWindowSize } from "../../store/topStateSlice"
import { TopState } from "../../constants/gameConstants"
import { GameVar, IPuzzle, ITopState } from "../../models/models"
import { useEffect, useState } from "react"
import { I18n } from "../../assets/i18n"
import { CgMenuGridR } from "react-icons/cg";
import { TbPuzzle } from "react-icons/tb"
import { BlackTower, WhiteTower } from "../../UI-components/Tower/GamePiece"

export const Puzzle = () => {
    const {
        viewMode: mode, theme
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'exp']))
    const {
        boardSize, cellSize
    } = useSelector(selectWithProps(['boardSize', 'cellSize'], 'board'))
    const {puzzles: ps, selectedPuzzle, puzzleResolved, windowSize} = useSelector(
        selectWithProps(['puzzles', 'selectedPuzzle', 'puzzleResolved', 'windowSize'], TopState)
    ) as ITopState
    const {exp: {onboardingPassed}, language} = useSelector(
        selectUserWithProps(['exp', 'language']))
        const i18n = I18n[language.slice(0, 2)] || I18n.en
    const puzzles = ps.slice().sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
    const navi = useNavigate()
    const dispatch = useDispatch()
    const puzz = useSelector(selectPuzzle)
    const [puzzle, setPuzzle] = useState(puzz as IPuzzle)
    const [title, setTitle] = useState(puzzle.description)
    const [congrats, setCongrats] = useState('')
    useEffect(()=> {
        if (puzz) {
            setPuzzle(puzz)
        }
    }, [puzz])
    const handleBack = () => {
        dispatch(choosePuzzle(''))
        // navi('..')
    }
    const handleNext = () => {
        const next = (puzzles.findIndex((p: IPuzzle) => p._id === selectedPuzzle) + 1) 
            % (puzzles.length)
        dispatch(choosePuzzle(puzzles[next]._id))
        navi(`../${puzzles[next]._id}`)
    }
    const NavCtrls = () => onboardingPassed 
        ? (
            <div className="puzzle-nav-wrapper">
                <button
                    className="puzzle-nav-btn"
                    type="button" 
                    onClick={handleBack}
                    title="back to puzzles menu"
                >
                    <CgMenuGridR />
                </button>
                <button
                    className="puzzle-nav-btn"
                    type="button" 
                    onClick={handleNext}
                    title="to next puzzle"
                >
                    <TbPuzzle />
                </button>
            </div>
        )
        : null
    // console.log(puzzle)
    useEffect(() => {
        const type = windowSize.width > 600 ? ` (${GameVar[puzzle.gType as 'tow']})` : ''
        const title = `${puzzle.description}${type}`
        setTitle(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzle])
    useEffect(() => {
        if (!puzzleResolved) return
        const randInd = Math.floor(Math.random()*i18n.puzzleCongrats.length)
        const congrats =  `${i18n.puzzleCongrats[randInd]}!`
        const oops = `${i18n.oops}`
        setCongrats(puzzleResolved.resolved ? congrats : oops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzleResolved])

    const bW = Math.min(cellSize*boardSize, 600)
    const onbCl = onboardingPassed ? '' : ' onb'
    return (        
        <div className={`puzzle-page bw-${bW} mode-${mode} theme-${theme}${onbCl}`}>
            <header className={``}>
                <div className="puzzle-title">
                    <span className="piece-box">{title.includes('White') ? <WhiteTower /> : <BlackTower />}</span>
                    &nbsp;<span>{title}</span>
                </div>
                {onboardingPassed 
                    ? null 
                    : (puzzleResolved 
                        ? <span className={puzzleResolved.resolved ? 'congrats' : ''}>
                            {congrats}
                          </span> 
                        :  <PuzzleMenu />)
                }
            </header>
            
            <div className="puzzle-page__content">
                <div className={`puzzle-main bw-${bW} mode-${mode} theme-${theme}`}>
                    <GameBoard />
                    {onboardingPassed 
                        ? <div className="puzzle-menu-wrapper"><NavCtrls /><PuzzleMenu /></div>
                        : null
                    }
                </div>
                {/* <SideBar side="right">
                    { width >= 900 ? <NavCtrls /> : null }
                    <GameMoves />
                    { width >= 900 ? <PuzzleMenu /> : null }
                </SideBar> */}
            </div>
        </div>
    )
}
