import { useDispatch, useSelector } from "react-redux"
import { I18n } from "../../assets/i18n"
import {Steps} from './OnbordingStep'
import { useState } from "react"
import storage from "../../common/storage"
import { selectUserWithoutProps } from "../../store/rootState&Reducer"
import { Axios, setAuthorizationHeader } from "../../common/axios"
import { setBackMessage } from "../../store/topStateSlice"
import { getOnbRatings } from "../../local-engine/gameplay-helper-fn"
import { saveUser } from "../../store/userSlice"

import './onb.scss'

export const Onboarding = () => {
    const userData = useSelector(selectUserWithoutProps(['games', 'userId', '_v']))
    // const dispatch = useDispatch()
    const {
        language, name, viewMode, theme, token, exp
    } = userData
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const [step, setStep] = useState(Object.keys(storage.getUser().exp.tow).length)
    const totalSteps = 4
    const dispatch = useDispatch()

    const handleStep = (step: number) => {
        if (step < totalSteps) {
            return setStep(step)
        }
        const nRatings = getOnbRatings(exp)
        const ratings = {
            tow: userData.ratings.tow.concat(nRatings.tow),
            rus: userData.ratings.rus.concat(nRatings.rus),
            int: userData.ratings.int.concat(nRatings.int),
        }
        storage.saveUser({...userData, ratings, exp: {...exp, onboardingPassed: true}})
        dispatch(saveUser({...userData, ratings, exp: {...exp, onboardingPassed: true}}))
        if (token) {
            try {
                setAuthorizationHeader(token)
                Axios.post('api/user/save', {...userData, ratings})
            } catch(e: any) {
                console.log(e)
                dispatch(setBackMessage({msg: e.response?.data?.message || e.message, ok: false}))
            }
        }
    }
    const Ps = [i18n.stepOneTitle, i18n.stepTwoTitle, i18n.stepPuzzlesTitle, i18n.stepLastTitle]
    // console.log(step)
    return (
        <main className={`onboarding-wrapper mode-${viewMode} theme-${theme}} noselect`}>
            <section className={`onboarding step-${step}`}>
                <header className={'onboarding_header'}>
                    {step === 0 ? <h2>Hi {name}!</h2> : null}
                    <p>{step !== 2 ? Ps[step] : null}</p>
                </header>
                <Steps step={step} confirm={handleStep as () => {}} />
            </section>
        </main>
    )
}

export default Onboarding
