

import Navbar from './Navbar'

import './header.scss'
import { NavLink } from 'react-router-dom'
import { I18n } from '../../assets/i18n'
import { Logo } from '../Logo/LogoIcon'
import { RoutePaths } from '../../constants/gameConstants'
import Engine from '../../local-engine/AIEngine'
import { useSelector } from 'react-redux'
import { IRootState } from '../../models/models'
import { selectMode, selectTheme } from '../../store/userSlice'
import React from 'react'


export const Header = React.memo(() => { 
    const localEngine = useSelector((state: IRootState) => state.topState.localEngine)
    const mode = useSelector(selectMode)
    const theme = useSelector(selectTheme)
    return (
        <>
            <header className={"header noselect mode-" + mode + " theme-" + theme}>
                <NavLink title="to home" to={RoutePaths.home} className="brand-logo">
                    <Logo size={21} />
                    <span>{I18n['en'].mainTitle}</span>
                </NavLink>
                <Navbar />
                {localEngine ? <Engine /> : null}
            </header>
            <div className="header-back" />
        </>
    )
})
