import axios from 'axios'

import storage from './storage'

import { Config } from '../env'
import { IStoredUser } from '../models/models'
import { isDev } from '../local-engine/gameplay-helper-fn'

const {token} = storage.getUser() as IStoredUser
isDev() && console.log(token)
     
const defaultAxiosConfig = {
    baseURL: Config[process.env.NODE_ENV || 'development'].baseURL,
    headers: {
        post: {
            'Content-Type': 'application/json',
        },
        common: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const Axios = axios.create(defaultAxiosConfig)
export const setAuthorizationHeader = (_token = token) => {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`
}
