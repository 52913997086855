// import React, { Suspense } from 'react'
// import Spinner from '../../UI-components/Spinners/Spinner'
import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { MdOutlineLightMode, MdOutlineDarkMode, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import {RxSpeakerLoud, RxSpeakerQuiet, RxSpeakerOff, RxPencil2} from "react-icons/rx"

import { Axios, setAuthorizationHeader } from "../../common/axios"
import { I18n } from "../../assets/i18n"
import storage from "../../common/storage"
import {setPieceColor, setTheme, setViewMode, setVolume, saveName, setToken, setArrowHighlight, setMoveHighlight, setBoardNotation, setView} from '../../store/userSlice'
import { useMessageShow } from "../../hooks/useShowMessage"
import { IRef, IRegedUser } from "../../models/models"
import validate from "../../common/helpers/inputValidation"
// import { selectUser } from '../../store/rootState&Reducer'
import { Board, BPCs, Themes, WPCs } from '../../constants/gameConstants'
import { Input } from '../../UI-components/Input/Input'
import { DemoBoard } from '../../UI-components/Board/DemoBoard'
import { LanguageSwitcher } from '../../UI-components/CtrlBtns/CtrlBtn'

import './profile.scss'
import { CustomSelect } from '../../UI-components/Input/MenuComponent';
import { isDev } from '../../local-engine/gameplay-helper-fn';
import { useNavigate } from "react-router-dom";
import { selectUserWithoutProps, selectWithProps } from "../../store/rootState&Reducer";


const ProfilePage = () => {
    const user = useSelector(selectUserWithoutProps(['userId', 'games', 'ratings'])) as IRegedUser
    const submitting = useRef(false)
    const {viewMode: mode, theme} = user
    const changed = useRef(false)
    const saved = useRef(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {token, ...data} = user
    const [menu, setMenu] = useState('top')
    const formRef: IRef<any> = useRef()
    const {showMessage} = useMessageShow()
    const i18n = I18n[data.language.slice(0, 2)] || I18n.en

    const handlesSaveSettings = async (leave = false) => {
        const reqData = {...user}
        isDev() && console.log(reqData)
        storage.saveUser(reqData)
        const reqBody = JSON.stringify(reqData)
        if (!token) {
            if (!leave) navigate(-1)
            return    
        }
        submitting.current  = true
        try {
            setAuthorizationHeader(token)
            const {data}= await Axios.post('api/user/save', reqBody)
            isDev() && console.log(data)
            dispatch(setToken(data.token))
        } catch(e: any) {
            showMessage({
                msg: `${e.response?.data?.message} Data saved locally`
                    || `${e.message} Data saved locally`,
                ok: false
            })
            
        } finally { 
            submitting.current = false
            changed.current = false
            saved.current = true
            !leave && navigate(-1) 
        }
    }
    useEffect(() => {
        // console.log(token, (changed.current && !saved.current))
        return () => {
            if (changed.current && !saved.current) {
                handlesSaveSettings(true)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleVolume = (vol: string) => {
        let volume
        switch (vol) {
            case 'down': {
                volume = data.volume > 0 ? data.volume - .1 : 0
                break
            }
            case 'up': {
                volume = data.volume < 1 ? data.volume + .1 : 1
                break
            }
            default: {
                volume = 0
            }
        }
        changed.current = true
        dispatch(setVolume(volume))
    }

    const handleBlur = async (e: any) => {
        if (!token || submitting.current) return
        const target = e.target as HTMLInputElement
        if (target.id !== "name") {
            return
        }
        const error = validate('name')(target.value)
        if (error.message) {
            showMessage({msg: error.message, ok: false})
        } else {
            const reqBody = JSON.stringify({name: data.name})
            try {
                setAuthorizationHeader(token)
                await Axios.post('api/auth/checkname', reqBody)
            } catch(e: any) {
                showMessage({msg: e.response?.data?.message || e.message, ok: false})
            }
        }
    }

    const handleTheme = (val: any) => {
        changed.current = true
        dispatch(setTheme(val))
    }

    const handleBColor = (val: any) => {
        let pieceColors = [...user.pieceColors]
        pieceColors[0] = val
        changed.current = true
        dispatch(setPieceColor(pieceColors))
    }

    const handleWColor = (val: any) => {
        let pieceColors = [...user.pieceColors]
        pieceColors[1] = val
        changed.current = true
        dispatch(setPieceColor(pieceColors))
    }

    const handleBN = (val: any) => {
        changed.current = true
        dispatch(setBoardNotation(val))
    }
    
    const handleView = (val: any) => {
        changed.current = true
        dispatch(setView(val))
    }
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        isDev() && console.log('submit')
        if (submitting.current) return
        handlesSaveSettings()
    }

    const change = (name: string) => {
        const form = formRef.current as HTMLElement
        if (!form) return
        form.querySelector(name)?.removeAttribute('readOnly')
    }

    const handleName = (e: any) => {
        dispatch(saveName(e.target.value))
    }

    const handleMode = () => {
        dispatch(setViewMode(user.viewMode === 'dark' ? 'light' : 'dark'))
    }
    const resetPassword = () => {
        navigate(`../auth/reset`)
    }

    const checkArrowHighlight = () => {
        dispatch(setArrowHighlight(!data.arrowHighlight))
    }

    const checkMoveHighlight = () => {
        dispatch(setMoveHighlight(!data.moveHighlight))
    }
    
    return (
        <div className={"noselect page-wrapper"} >
            <div className={`profile-page mode-${mode} theme-${theme}`}>
                <form 
                    className="settings-form" 
                    onSubmit={handleSubmit} 
                    ref={formRef}
                    onBlur={handleBlur}
                >
                    <div 
                        role="button"
                        className={`menu-group ${menu === 'top' ? ' open' : ''}`} 
                        onClick={() => setMenu('top')}
                    >
                        <p>App settings</p>
                        <div className={`group-wrapper${menu === 'top' ? ' open' : ''}`}>
                            <div className="setting-field-wrapper">
                                <div className='inp-label'>Mode: </div>
                                <div className='btns-wrapper'>
                                    <div/>
                                    <button
                                        type="button" 
                                        className="mode-btn"
                                        onClick={handleMode}
                                    >
                                        {data.viewMode === 'dark' ? <MdOutlineLightMode/> : <MdOutlineDarkMode/>}
                                    </button>
                                </div>
                            </div>
                            <div className="setting-field-wrapper">
                                <div className='inp-label'>{i18n.language || 'Language'}: </div>
                                <LanguageSwitcher />
                            </div>
                            <div className="setting-field-wrapper">
                                <div className='inp-label'>{i18n.volume || 'Volume'}:</div>
                                <div className='btns-wrapper'>
                                    <button 
                                        type="button"
                                        className='volume-btn'
                                        onClick={() => handleVolume('off')}
                                    >
                                        <RxSpeakerOff />
                                    </button>
                                    <button 
                                        type="button"
                                        className='volume-btn'
                                        onClick={() => handleVolume('down')}
                                        disabled={data.volume === 0}
                                    >
                                        <RxSpeakerQuiet />
                                    </button>
                                    <input type='text' value={data.volume * 10} id="volume" readOnly /> 
                                    <button 
                                        type="button"
                                        className='volume-btn'
                                        onClick={() => handleVolume('up')}
                                        disabled={data.volume === 1}
                                    >
                                        <RxSpeakerLoud />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        role="button"
                        className={`menu-group${menu === 'color' ? ' open' : ''}`} 
                        onClick={() => setMenu('color')}>
                        <p>Color settings</p>
                        <div className={`group-wrapper${menu === 'color' ? ' open' : ''}`}>
                            <div className="setting-field-wrapper sel-wrp">
                                <label className='inp-label'>{i18n.theme || 'Theme'}:</label>
                                <CustomSelect 
                                    selectId='theme' 
                                    handler={handleTheme} 
                                    menu={Themes.map(i => ({name: i, value: i}))} 
                                    actual={data.theme} 
                                    classN={'select-wrapper'}
                                /> 
                            </div>
                            <div className="setting-field-wrapper sel-wrp">
                                <label className='inp-label'>{i18n.pc || '"Black" piece color'}:</label>
                                <CustomSelect 
                                    selectId={"pc0"}
                                    actual={data.pieceColors[0]}
                                    classN='select-wrapper'
                                    handler={handleBColor}
                                    menu={BPCs.map(i => ({name: i, value: i}))}
                                />
                                    
                            </div>
                            <div className="setting-field-wrapper sel-wrp">
                                <label className='inp-label'>{i18n.pc || '"White" piece color'}:</label>
                                <CustomSelect 
                                    selectId={"pc1"}
                                    actual={data.pieceColors[1]}
                                    handler={handleWColor}
                                    classN='select-wrapper'
                                    menu={WPCs.map(i => ({name: i, value: i}))}
                                />
                            </div>
                            <div className="setting-field-wrapper">
                                <DemoBoard />    
                            </div>
                        </div>
                    </div>
                   
                    <div
                        role="button"
                        className={`menu-group${menu === 'piece' ? ' open' : ''}`} 
                        onClick={() => setMenu('piece')}
                    >
                        <p>Board and pieces settings</p>
                        <div className={`group-wrapper${menu === 'piece' ? ' open' : ''}`}>
                            <div className="setting-field-wrapper">
                                <label className='inp-label'>{i18n.arrowHighlight || 'Arrow highlight'}</label>
                                <button 
                                    className="check-btn" 
                                    type="button" 
                                    onClick={checkArrowHighlight}
                                >
                                    { data.arrowHighlight
                                        ? <MdOutlineCheckBox />
                                        : <MdOutlineCheckBoxOutlineBlank />
                                    }
                                </button>
                            </div>
                            <div className="setting-field-wrapper">
                                <label className='inp-label'>{i18n.arrowHighlight || 'Move highlight'}</label>
                                <button 
                                    className="check-btn" 
                                    type="button" 
                                    onClick={checkMoveHighlight}
                                >
                                    { data.moveHighlight
                                        ? <MdOutlineCheckBox />
                                        : <MdOutlineCheckBoxOutlineBlank />
                                    }
                                </button>
                            </div>
                            <div className="setting-field-wrapper sel-wrp">
                                <label className='inp-label'>{i18n.notation || 'Board notation'}:</label>
                                <CustomSelect 
                                    selectId={"notation"}
                                    actual={data.boardNotation}
                                    handler={handleBN}
                                    classN='select-wrapper'
                                    menu={['chess', 'draughts', 'none'].map(i => ({name: i, value: i}))}
                                />
                            </div>
                            <div className="setting-field-wrapper sel-wrp">
                                <label className='inp-label'>{i18n.pieceView || 'Piece view'}:</label>
                                <CustomSelect 
                                    selectId={"view"}
                                    actual={data.towerView}
                                    handler={handleView}
                                    classN='select-wrapper'
                                    menu= {['face', 'none'].map(i => ({name: i, value: i}))}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        role="button"
                        className={`menu-group${menu === 'personal' ? ' open' : ''}`} 
                        onClick={() => setMenu('personal')}
                    >
                        <p>Personal settings</p>
                        <div className={`group-wrapper${menu === 'personal' ? ' open' : ''}`}>
                            <div className="setting-field-wrapper">
                                <Input
                                    id="name"
                                    name="Name"
                                    type='text'
                                    value={data.name}
                                    handleChanges={handleName}
                                    handleBlur={handleBlur}
                                    readonly={true}
                                />
                                <button 
                                    type="button"  
                                    className="change-btn" 
                                    onClick={() => change('#name')}
                                >
                                    <RxPencil2 />
                                </button>
                            </div>
                            <div className="setting-field-wrapper">
                                <Input
                                    id="password"
                                    name="Password"
                                    type='password'
                                    value={'***********'}
                                    handleChanges={() => {}}
                                    readonly={true}
                                />
                                <button 
                                    type="button"  
                                    className="change-btn" 
                                    onClick={resetPassword}
                                >
                                    <RxPencil2 />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div 
                        href="#""
                        role="button"
                        className={`menu-group${menu === 'rules' ? ' open' : ''}`} 
                        onClick={() => setMenu('rules')}
                    >
                        <p>Rules</p>
                        <div className={`group-wrapper${menu === 'rules' ? ' open' : ''}`}>
                           #TODO Rules
                        </div>
                    </div> */}
                    <button 
                        type="submit" 
                        className="save-settings-btn noselect"
                    >
                        {i18n.saveButton || 'Save'}
                    </button>
                    
                </form>
            </div>
        </div>
    )
}   

export default ProfilePage
