import { useDispatch, useSelector} from "react-redux"
import { TiLightbulb } from "react-icons/ti";
import { MdOutlineAutorenew } from "react-icons/md";
import { help } from '../../store/gameSlice'
import { turnBoard } from "../../store/boardSlice";
import { selectBoardWithProps, selectPuzzle } from "../../store/rootState&Reducer";

import { IRootState } from "../../models/models";



export const PuzzleMenu = (props: any) => {
    const {reversedBoard: revBoard} = useSelector(selectBoardWithProps(['reversedBoard']))
    const puzzle = useSelector(selectPuzzle)
    // const resolved = useSelector((state: IRootState) => state.topState.puzzleResolved)
    const dispatch = useDispatch()
    const hintMove = useSelector((state: IRootState) => state.game.hintMove)
    const noHint = !hintMove && puzzle?.level > 3

    const turnBoardOver = () => {
        dispatch(turnBoard(!revBoard))
    }

    const handleHelp = () => {
        if (noHint) return
        dispatch(help(true))
    }

    // const undoClass = `puzzle-menu__item${lastMove.num >= 0 ? ' undo' : " hide-undo"}`
    const hintClass = `puzzle-menu__item hint${noHint ? ' disabled' : ''}${puzzle?.level <= 3 ? ' anim' : ''}`
    if (true) {
        return (
            <ul className="puzzle-menu">
                <li 
                    title="turn board" 
                    role="button" 
                    className="puzzle-menu__item revert" 
                    onClick={turnBoardOver}
                >
                   <MdOutlineAutorenew />
                </li>
                <li 
                    title="hint" 
                    role="button" 
                    className={hintClass} 
                    onClick={handleHelp}
                >
                    <TiLightbulb />
                </li>                
            </ul>
        )
    }
}
