import React, { useEffect, useRef, useState } from "react"
import { v4 } from 'uuid'

import { 
    copyObj, fullPosition, getRandomDebuteMove, isDev, positionSummary
} from "./local-engine/gameplay-helper-fn"
import posTree from "./local-engine/moves-tree"
// import { moveResolver as moveR } from "./local-engine/move-resolver"
import { PieceColor as PC } from "./models/models"
import { createStartPosition } from "./local-engine/board-helper-fn"
import { wsService } from "./common/ws-service"


const getEngLevel = (rating: number) => {
    return Math.round(rating / 300)
}
const getId  = () => v4().replaceAll('-', '').slice(0, 24)
// const TimeToConnect = 500
const botId = getId()

const botState = {
    gameKey: '',
    readyState: 3,
    ping: 0,
    botId,
    engineColor: '',
    moves: '',
    moveLs: [] as any[],
    msQueue: [] as any[],
    attempts: 0
}

const PingInterval = 29000

const Bot = () => {
    const idSend = useRef(false)
    const gameKeyR = useRef('')
    const botIdR = useRef(botState.botId)
    const intervals = useRef({
        interval: null as unknown as NodeJS.Timeout,
        timeout: null as unknown as NodeJS.Timeout,
    } as any)
    const [ws, setWs] = useState(wsService.ws)
    const [state, setState] = useState({...botState})
    useEffect(() => {
        wsService.setToken(true)
        const unsubWs = wsService.$ws.subscribe(setWs)
        return () => {
            clearInterval(intervals.current.interval)
            clearTimeout(intervals.current.timeout)
            unsubWs()
        }
    }, [])

    useEffect(() => {
        console.log('ws', ws?.readyState, idSend.current)
        if (!ws || ws?.readyState !== 1 || !ws.send) {
            idSend.current = false
            return
        }
        if (!idSend.current) {
            idSend.current = true
            const mess = JSON.stringify({
                message: 'botId', payload: {id: botIdR.current, gameKey: gameKeyR.current}
            })
            ws.send(mess)
        }
        if (state.msQueue.length) {
            for (const mess of state.msQueue) {
                if (mess.message !== 'botId') {
                    ws.send(JSON.stringify(mess))
                }
            }
            const st = {...state, msQueue: []}
            setState(st)
        }
        ws.onmessage = (event: any) => {
            const data = JSON.parse(event.data)
            console.log('Bot received message: ', data);
            messageResolver(data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws])


    const ping = (mes: any = 'ping') => {
        if (!ws || ws?.readyState > 1) return
        ws.send(mes)
    }

    const pingPong = () => {
        const message = JSON.stringify({message: 'ping'})
        if (intervals.current.interval) {
            clearInterval(intervals.current.interval)
        }
        intervals.current.interval = setInterval(ping, PingInterval, message)
    }

    const resolveMove = (data: any) => {
        const move = posTree.resolvePlayerMove(data)
        if (Object.keys(data.position).length 
            && positionSummary(posTree.currentPos) !== positionSummary(data.position)) {
            console.error('invalid position', posTree.currentPos, data.position)
            throw new Error('invalid move')
        }
        if (!move) {
            return {move: ''}
        }
        posTree.updateTreeAfterMove(move)
        isDev() && console.warn('engine move', move, copyObj(posTree))
        return {move}
    }
    
    const resolveFirstMove = () => {
        const move = getRandomDebuteMove(posTree.getOrderedMoves())
        posTree.updateTreeAfterMove(move)
        return {move}
    }

    const messageResolver = (props: any) => {
        const {message, payload} = props
        switch (message) {
            case 'id': {
                botIdR.current = payload
                const nState = {...state, botId: payload}
                setState(nState)
                break
            }
            case 'bot added': {
                const nState = {...state, readyState: ws?.readyState}
                setState(nState)
                pingPong()
                break
            }
            case 'game move': {
                payload.position = fullPosition(payload.position)
                const res = resolveMove(payload)
                const val = posTree.getRoot().deepValue.value
                console.log('game move payload', payload, res, val, state.engineColor)
                if ((state.engineColor === PC.white && val < -7)
                    || (state.engineColor === PC.black && val > 7)) {
                    if (ws.send) {
                        const gameKey = gameKeyR.current
                        gameKeyR.current = ''
                        ws.send(JSON.stringify({
                            message: 'game surrender', payload: {gameKey}
                        }))
                        const nState = {...state, moves: '', gameKey: ''}
                        setState(nState)
                        ws.send(JSON.stringify({message: 'bot ready'}))
                        pingPong()
                        return
                    } 
                }
                
                if (!res.move) {
                    gameKeyR.current = ''
                    const nState = {...state, moves: '', gameKey: ''}
                    setState(nState)
                } else {
                    const mess = {
                        message: 'game move', 
                        payload: {move: res.move, gameKey: gameKeyR.current}
                    }
                    const mLen = state.moves.length
                    const moves = state.moves + (mLen ? "_" : '') + res.move
                    const nState = {...state, moves}
                    if (ws.send) {
                        ws.send(JSON.stringify(mess))
                    } else {
                        nState.msQueue = state.msQueue.concat(mess)
                    }
                    setState(nState)
                }
                break
            }
            case 'new game': {
                clearInterval(intervals.current.interval)
                const {GV, turn, game = true, white, gameKey} = payload
                const size = +payload.bSize
                const position = payload.position || createStartPosition(size, GV) 
                const st = Date.now()
                if (!size || !GV || !white || !gameKey) {
                    console.error('invalid props to set up engine worker', payload)
                    return
                }
                gameKeyR.current = gameKey
                const engineColor = white.userId === botIdR.current ? PC.white : PC.black
                const engineLevel = getEngLevel(white.rating)
                const EngineProps = {engineLevel, engineColor, position, turn, game, size, GV}
                posTree.setPropsAndCreateTree(EngineProps)
                isDev() && console.log('engine set up successfully', copyObj(posTree), Date.now() - st)
                const mess = {
                    message: 'game ready to play',
                    payload: posTree.engineColor === PC.white
                        ? {wReady: 1, gameKey}
                        : {bReady: 1, gameKey}
                }
                const nState = {...state, gameKey, engineColor}
                if (ws.send) {
                    ws.send(JSON.stringify(mess))
                } else {
                    nState.msQueue = [mess]
                }
                console.log('new game state', nState)
                setState(nState)
                break
            }
            case 'draw offered': {
                const currentValue = posTree.getRoot().deepValue.value
                const piecesNumber = Object.keys(posTree.currentPos).length
                const accept = piecesNumber < 3 && Math.abs(currentValue) < 2
                const mess = {message: accept ? 'draw accepted' : 'draw declined'}
                if (!ws.send) {
                    const nState = {...state, msQueue: state.msQueue.concat(mess)}
                    setState(nState)
                    return 
                }
                ws.send(JSON.stringify(mess))
                break
            }
            case 'game ended': {
                gameKeyR.current = ''
                setState({...state, gameKey: '', moves: ''})
                ws.send(JSON.stringify({message: 'bot ready'}))
                pingPong()
                break
            }
            case 'game canceled': {
                gameKeyR.current = ''
                const nState = {...state, gameKey: '', moves: ''}
                setState(nState)
                ws.send(JSON.stringify({message: 'bot ready'}))
                pingPong()
                break
            }
            case 'game started': {
                if (posTree.engineColor === PC.white) {
                    const move = resolveFirstMove()
                    const mess = {
                        message: 'game move', 
                        payload: {...move, gameKey: gameKeyR.current}
                    }
                    if (!ws.send) {
                        const nState = {...state, msQueue: state.msQueue.concat(mess)}
                        setState(nState)
                        return
                    } 
                    console.log('first', move)
                    ws.send(JSON.stringify(mess))
                }
                break
            }
            default: {
                break
            }
        }
    }
    return (
        <div className="bot-page">
            <div className="bot-container">
                <div>gameKey: <span id='gameKey'>{state.gameKey}</span></div> 
                <div>messQueue: <span id='messQueue'>{JSON.stringify(state.msQueue)}</span></div>
                <div>attempts: <span id='attempts'>{state.attempts}</span></div>
                <div>botId: <span id='botId'>{state.botId}</span></div> 
                <div>EngineColor: <span id='color'>{state.engineColor}</span></div> 
                <div>pings: <span id='ping'>{state.ping}</span></div> 
                <div>moves: <div id='moves'>{state.moves}</div></div>
                {/* <div>moveLs: <span id='moveLs'>${state.moveLs}</span></div> */}
            </div>
        </div>
        
    )
}

export default Bot
