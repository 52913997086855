import {Observable} from "./observable";

import { env } from '../env'
import { isDev } from "../local-engine/gameplay-helper-fn";

export class WSService {
    ws = {readyState: 3} as WebSocket
    connecting = 0
    connected = 0
    attempts = 0
    token = false
    timeouts = null as unknown as NodeJS.Timeout
    $ws = new Observable(this.ws)
    constructor() {
        if (this.token) {
            this.connect()
        }
    }
  
    connect = () => {
        // console.log(this.ws.readyState, this.connecting, this.connected)
        if (this.connecting || this.connected) return
        this.connecting = Date.now()
        this.attempts = (this.attempts + 1) % 5
        console.log('connecting')
        this.ws = new WebSocket(env.wsUrl)
        this.ws.onopen = () => {
            console.log('connected', this.ws.readyState)
            this.connecting = 0
            this.connected = 1
            this.$ws.emit(this.ws)
        };
        this.ws.onclose = () => {
            isDev() && console.error('WebSocket disconnected', this.ws?.readyState, this.token);
            this.connecting = 0
            this.connected = 0
            this.$ws.emit(null as unknown as WebSocket)
            if (this.token) {
                this.reconnect()
            }
            
        };
    }

    setToken = (token = false) => {
        this.token = token
        if (token && !this.connected) {
            this.reconnect()
        }
    }

    reconnect = () => {
        if (!this.attempts && !this.connecting) {
            this.connect()
        } else {            
            this.timeouts = setTimeout(this.connect, this.attempts * this.attempts * 500)
        }
    }
}

export const wsService = new WSService()
export default WSService
