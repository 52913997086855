import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './showMessage.scss'
import { AppType, IRootState } from '../models/models'
// import {isDev } from '../local-engine/gameplay-helper-functions'
import { 
    gameHandlers, 
    pieceSetupHandlers,
    pieceRemoveHandlers,
    towerRemoveHandlers,
    towerSetupHandlers,
    EventHandlers,
    kingSetHandlers,
    IHookEvent,
    IMDownProps,
    IMMoveProps,
    IMUpProps
} from '../local-engine/eventHandlers'
import moveAudio from '../common/audio'
import { 
    selectBoardWithProps, selectGameWithProps, selectIsTouchable, selectUserWithProps, selectWithProps 
} from '../store/rootState&Reducer'
import { setTouchedTower } from '../store/boardSlice'
import { makeMove, setMoveStep, tryUpdatePosition, updatePieceDOM, updatePieces, updatePosition } from '../store/gameSlice'
// import { getNextMove } from '../local-engine/gameplay-helper-fn'
import { Analysis, GameSettings } from '../constants/gameConstants'
import { copyObj, splitMove } from '../local-engine/gameplay-helper-fn'


const DefaultHadlers = {
    onMouseDown: (porps) => {}, 
    onMouseMove(props) {},
    onMouseUp(props) {},
} as EventHandlers

export const useHandler = () => {
    const dispatch = useDispatch()
    const onb = useSelector((state: IRootState) => !state.user.exp.onboardingPassed)
    const {
        towerTouched, cellSize, cellsMap
    } = useSelector(selectBoardWithProps(['towerTouched', 'cellSize', 'cellsMap']))
    const {
        removingPieces: RP, settingPosition: SP, settingKing: SK,
    } = useSelector(selectWithProps(["removingPieces", "settingPosition", "settingKing"], Analysis))
    const {gameStarted} = useSelector(selectWithProps(['gameStarted'], GameSettings))
    const GV = useSelector((state: IRootState) => state.gameSettings.gameVariant)
    const AT = useSelector((state: IRootState) => state.topState.type)
    const {
        nextMoves,
        moveStep,
        position,
        turn
    } = useSelector(selectGameWithProps([ 
        'nextMoves', 
        'moveStep', 
        'position',
        'turn'
    ]))
    
    const {volume} = useSelector(selectUserWithProps(['volume']))
    const [handlersWithDispatch, setHandlersWithDispatch] = useState(DefaultHadlers)
    const touchable = useSelector(selectIsTouchable)
    useEffect(() => {
        let handlers = gameHandlers as EventHandlers
        switch (true) {
            case SK: {
                handlers = kingSetHandlers
                break
            }
            case SP && RP && GV === 'towers': {
                handlers = towerRemoveHandlers
               break
            }
            case SP && RP && GV !== 'towers': {
                handlers = pieceRemoveHandlers
                break
            }
            case AT === 'game' || (AT === 'analysis' && !SP) || onb: {
                handlers = gameHandlers
                break
            }
            case AT === 'analysis' && SP && !RP && GV === 'towers': {
                handlers = towerSetupHandlers
                break
            }
            case AT === 'analysis' && SP && !RP && GV !== 'towers': {
                handlers = pieceSetupHandlers
                break
            }
            default: {
                handlers = gameHandlers
            }
        }
        const handlersWithDispatch = {
            onMouseDown: (event: IHookEvent) => {
                if ((Array.isArray(touchable) && !touchable.includes(event.key)) || !touchable) return
                const props: IMDownProps = {
                    event, cellSize, cellsMap, nextMoves, moveStep, position, turn
                }
                
                const {towerTouched, pieces, towers} = handlers.onMouseDown(props)
                towerTouched && dispatch(setTouchedTower(towerTouched))
                pieces && dispatch(updatePieces(pieces))
                towers && dispatch(updatePosition(towers))
            },
            onMouseMove: (event: IHookEvent) => {
                if (!towerTouched) return
                const props: IMMoveProps = {event, towerTouched, position}
                const {key, DOM} = handlers.onMouseMove(props)
                // console.log(event, props)
                DOM && dispatch(updatePieceDOM({key, DOM}))
            },
            onMouseUp: (event: IHookEvent) => {
                if (!towerTouched || (!SP && !nextMoves.length)) return
                const props: IMUpProps = {
                    event, towerTouched, position, cellSize, cellsMap, nextMoves, moveStep
                }
                const {
                    towers, makedMove, pieces, moveStep: MS = 0, towerTouched: TT = null
                } = handlers.onMouseUp(props)
                dispatch(setTouchedTower(TT))
                let from = ''
                if (makedMove) {
                    from = splitMove(makedMove.move)[0]
                    if (makedMove.position[from]) {
                        console.error('ivalid position', copyObj(makedMove))
                        delete makedMove.position[from]
                    }
                    dispatch(setMoveStep(MS))
                    if (volume) {
                        const audio = moveAudio()
                        audio.volume = volume
                        audio.play()
                    }
                    dispatch(makeMove(makedMove))
                    return
                }
                if (towers && pieces) {
                    console.error('fail in mouse up', pieces, towers)
                }
                if (towers) {
                    dispatch(tryUpdatePosition(towers))                
                } 
                else if (pieces) dispatch(updatePieces(pieces))
                !SP && dispatch(setMoveStep(MS))
            }
        }
        setHandlersWithDispatch(handlersWithDispatch as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GV, SP, AT, RP, SK, cellSize, position, nextMoves, moveStep, towerTouched, gameStarted])
    
    // isDev() && console.log(handlers)   
    return handlersWithDispatch
}
