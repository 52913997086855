import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
// import {} from 'react-icons/rx'

import { Languages } from "../../constants/gameConstants"
import useCloseEffect from "../../hooks/useCloseEffect"
import { selectMode, setViewMode, setVolume, selectLanguage, setLanguage } from "../../store/userSlice"

import './ctrl-btn.scss'

export const ModeController = () => {
    const mode = useSelector(selectMode)
    const dispatch = useDispatch()
    const handleMode = () => {
        dispatch(setViewMode(mode === 'light' ? 'dark' : 'light'))
    }
    return (
        <button
            className="ctrl-btn material-symbols-outlined"
            type="button"
            onClick={handleMode}
        >
            {`${mode}_mode`}
        </button>
    )
}

export const SoundController = () => {
    const dispatch = useDispatch()
    const handleVolume = () => {
        dispatch(setVolume(0))
    }
    return (
        <button
            className="ctrl-btn"
            type="button"
            onClick={() => handleVolume()}
        >
            {/* <RxSpeakerOff /> */}
        </button>
    )
}

export const LanguageSwitcher = () => {
    const lang = useSelector(selectLanguage).slice(0, 2)
    const dispatch = useDispatch()
    const [dropdown, setDropdownOpen] = useState(false)
    const addListeners = useCloseEffect('lang-dropdown-btn', setDropdownOpen, true)
    const handleClick = (l: string) => {
        console.log(l)
        dispatch(setLanguage(l))
        setDropdownOpen(false)
    };
    return (
        <div title="change language" className="lang-menu">
            <button 
                type='button' 
                onClick={() => {setDropdownOpen(!dropdown); addListeners(true)}}
                className='lang-dropdown-btn'
            >{lang}</button>
            <div className={"lang-dropdown" + (dropdown ? ' open' : '')}>
                {Languages.map(l => {
                    return (
                        <button 
                            type='button'
                            onClick={() => handleClick(l)}
                            onTouchEnd={() => handleClick(l)}
                            key={l}
                            className={'lang-btn' + (l === lang ? ' lang-selcted' : '')}
                        >
                            {l}
                        </button>
                    )
                })}
            </div>
        </div>
    )
};
