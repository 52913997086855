/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { I18n } from "../../assets/i18n"
import { selectLanguage, updateExp } from "../../store/userSlice"
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md"
import { ReactNode, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { RoutePaths, TopState } from "../../constants/gameConstants"
import storage from "../../common/storage"
import { selectPuzzle, selectUserWithProps, selectWithProps } from "../../store/rootState&Reducer"
import { IExpirience, IPuzzle } from "../../models/models"
import { onbPuzzleStartLevel } from "../../local-engine/gameplay-helper-fn"
import { Puzzle } from "../../Pages/Puzzles/Puzzle"
import { choosePuzzle } from "../../store/topStateSlice"

export interface IRadioProps {
    name: string, 
    menu: any[], 
    onSelect: (c: any) => {},
    actual: number
}

export  const CustomRadioBtn = (
    props: IRadioProps
) => {
    const [radio, setRadio] = useState(props.menu[0].value)
    const onClick = (v: number) => {
        setRadio(v)
        props.onSelect({key: props.name, value: v})
    }
    return (
        <div className={`radio-wrapper ${props.actual ? ' shown' : ''}`}>
            <div className="radio-container">
                {props.menu.map(i => (
                    <label className="radio-lbl" key={i.lbl}>
                        {i.lbl}
                        <button 
                            type="button"
                            onClick={() => onClick(i.value)}
                            className={`radio-btn ${radio == i.value ? 'selected' : ''}`}
                        />
                    </label>))
                }
            </div>
        </div>
    )
}

export const CheckMenu = (
    props: {formfield: {n: string, v: number},
            label1: string, 
            label2?:string, 
            handlers: any,
            children?: ReactNode,
        }
) => {
    const {formfield, label1, label2, handlers, children} = props
    const ln = useSelector(selectLanguage)
    const i18n = I18n[ln.slice(0, 2)] || I18n.en
    const handleYes = () => {
        handlers.handleYes(formfield.n)
    }
    const handleNo = () => {
        handlers.handleNo(formfield.n)
    }
    return (
        <div className='check-wrapper'>
            <label className="check-label">
                {label1 || 'Have you ever played checkers?'}
                <div className="check-btn-wrapper">
                    <button 
                        className="check-btn" 
                        type="button" 
                        onClick={handleYes}
                    >
                        <span>{i18n.yes || 'Yes'}</span>
                        { formfield.v
                            ? <MdOutlineCheckBox />
                            : <MdOutlineCheckBoxOutlineBlank />
                        }
                    </button>
                    <button 
                        className={`check-btn ${!formfield.v ? ' checked' : ''}`}
                        type="button" 
                        onClick={handleNo}
                    >
                        <span>{i18n.no || 'No'}</span>
                        { !formfield.v
                            ? <MdOutlineCheckBox />
                            : <MdOutlineCheckBoxOutlineBlank />
                        }
                    </button>
                </div>
            </label>
            {/* <div className="check-children-wrapper"> */}
            {
                children ?
                children
                : <label className={`input-lbl rating ${formfield.v ? ' shown' : ''}`}>
                    <span>{label2}</span>
                        <input 
                            name={`${formfield.n}`}
                            onChange={handlers.onChange} 
                            type="number"
                            step={50}
                            min={100}
                            max={2400}
                            value={formfield.v} 
                        >
                    </input>
                </label>
            }                     
        </div>
    )
}

export const OnboardingStepOne = (props: {confirm: (a: number) => {}}) => {
    const {language, exp} = useSelector(selectUserWithProps(['exp', 'language']))
    const [form, setForm] = useState({
        rus: {dRate: 0}, int: {dRate: 0}, tow: {dRate: 0}, either: {dRate: 0}})
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const dispatch = useDispatch()
   
    const handleConfirm = () => {
        console.log(form)
        dispatch(updateExp(form))
        storage.saveUser({exp: {...exp, ...form}})
        props.confirm(1)
    }
    const handleYes = (key: string) => {
        setForm(state => ({...state, [key]: {dRate: 300}}))
    }
    const handleNo = (key: string) => {
        setForm(state => ({...state, [key]: {dRate: 0}}))
    }
    const onChange = (e: any) => {
        const {name, value} = e.target
        setForm(form => ({...form, [name]: {dRate: Math.max(50, parseInt(value)%2400)}}))
    }
    const handlers = {
        handleYes,
        handleNo,
        onChange
    }
    return (
        <article className="onboarding_step step-0">
            <button type="button" className="skip-btn" onClick={handleConfirm}>skip</button>
            <div role="form">
                <CheckMenu 
                    formfield={{n: 'either', v: form.either.dRate}} 
                    label1={i18n.step0che}
                    label2={i18n.step0extra} 
                    handlers={handlers} 
                />
                <CheckMenu 
                    formfield={{n: 'int', v: form.int.dRate}} 
                    label1={i18n.step0int}
                    label2={i18n.step0extra} 
                    handlers={handlers} 
                />
                <CheckMenu 
                    formfield={{n: 'rus', v: form.rus.dRate}} 
                    label1={i18n.step0rus}
                    label2={i18n.step0extra} 
                    handlers={handlers} 
                />
                <CheckMenu 
                    formfield={{n: 'tow', v: form.tow.dRate}} 
                    label1={i18n.step0tow}
                    label2={i18n.step0extra} 
                    handlers={handlers} 
                />
              
                <button 
                    type="submit" 
                    onClick={handleConfirm} 
                    className="confirm-btn"
                >
                    {i18n.confirm}
                </button>
            </div>
        </article>
    )
}

export const OnboardingStepTwo = (props: {confirm: (c: number) => {}}) => {
    const {language, exp} = useSelector(selectUserWithProps(['exp', 'language']))
    const {onboardingPassed: oP, ...formData} = exp
    const [form, setForm] = useState({
        either: {...formData.either, rules: 0},
        tow: {...formData.tow, rules: 0},
        rus: {...formData.rus, rules: 0},
        int: {...formData.int, rules: 0},
    } as IExpirience)
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const dispatch = useDispatch()
    // const rules = user.exp.rules || {}
    const handleConfirm = () => {
        console.log(form)
        dispatch(updateExp(form))
        storage.saveUser({exp: {...storage.getUser().exp, ...form}})
        props.confirm(2)
    }

    const handleYes = (key: string) => {
        setForm(state => ({...state, [key]: {...state[key], rules: 1}}))
    }
    const handleNo = (key: string) => {
        setForm(state => ({...state, [key]: {...state[key], rules: 0}}))
    }
    
    const handlers = {
        handleYes,
        handleNo,
        onChange: () => {}
    }

    const onSelect = (c: any) => {
        setForm(state => ({...state, [c.key]: {...state[c.key], rules: c.value}}))
        // dispatch(setExp({rules: {...rules, ...c}}))
    }

    const menu = [
        {lbl: i18n.rulesKnown1, value: 1}, {lbl:  i18n.rulesKnown2, value: 2}, {lbl:  i18n.rulesKnown3, value: 3}
    ]
    return (
        <article className="onboarding_step step-2">
            <button type="button" className="skip-btn" onClick={handleConfirm}>skip</button>
            <div role="form">
                <CheckMenu 
                    formfield={{n: 'either', v: form.either.rules}} 
                    label1={i18n.step1che} 
                    handlers={handlers} 
                >
                    <CustomRadioBtn
                        actual={form.either.rules}
                        name="either" 
                        menu={menu} 
                        onSelect={() => onSelect as () => {}} 
                    />
                </CheckMenu>
                <CheckMenu 
                    formfield={{n: 'int', v: form.int.rules}} 
                    label1={i18n.step1int} 
                    handlers={handlers} 
                >
                    <CustomRadioBtn
                        actual={form.int.rules} 
                        name="int" 
                        menu={menu} 
                        onSelect={onSelect as () => {}} 
                    />
                </CheckMenu>
                <CheckMenu 
                    formfield={{n: 'rus', v: form.rus.rules}} 
                    label1={i18n.step1rus} 
                    handlers={handlers} 
                >
                   <CustomRadioBtn
                        actual={form.rus.rules}
                        name="rus" 
                        menu={menu} 
                        onSelect={onSelect as () => {}} 
                    />
                </CheckMenu>
                <CheckMenu 
                    formfield={{n: 'tow', v: form.tow.rules}} 
                    label1={i18n.step1tow}
                    handlers={handlers} 
                >
                    <CustomRadioBtn
                        actual={form.tow.rules}
                        name="tow" 
                        menu={menu} 
                        onSelect={onSelect as () => {}} 
                    />
                </CheckMenu>
                <button 
                    type="submit" 
                    onClick={handleConfirm} 
                    className="confirm-btn"
                >
                    {i18n.confirm}
                </button>
            </div>
        </article>
    )
}

export const OnboardingStepThree = (props: {confirm: (c: number) => {}}) => {
    const {exp} = useSelector(
        selectUserWithProps(['exp', 'language'])
    )
    const progress = useRef({success: 0, fail: [] as number[], startLevel:  onbPuzzleStartLevel(exp)})
    const {puzzles, puzzleResolved, selectedPuzzle} = useSelector(
        selectWithProps(["puzzles", "puzzleResolved", "selectedPuzzle"], TopState)
    )
    const puzzle = useSelector(selectPuzzle) as IPuzzle
    const dispatch  = useDispatch()
    const [level, setLevel] = useState(progress.current.startLevel || 0)
    // const i18n = I18n[language]

    useEffect(() => {
        console.log('onb puzzles', puzzles, level, progress)
        if (!puzzles.length || level >= 11) {
            props.confirm(3)
            return
        }
        // const pzls = puzzles.filter((p: IPuzzle) => p.level == level)
        let choosen = puzzles.filter((p: IPuzzle) => p.level == level)[0]?._id
        if (choosen) {
            dispatch(choosePuzzle(choosen))
        } else {
            choosen = puzzles.filter((p: IPuzzle) => p.level >= level)[0]?._id
            if (choosen) dispatch(choosePuzzle(choosen))
            else props.confirm(3)
        }
    }, [level])

    useEffect(() => {
        if (!puzzleResolved?.animated) return
        if (puzzleResolved?.resolved) {
            progress.current.success = level
            progress.current.fail.length = 0
            level <= 10 && setLevel(Math.floor(level + 1))
            console.log(puzzle)
            puzzle && dispatch(updateExp({[puzzle.gType]: {...exp[puzzle.gType], puzzles: level}}))
        } else {
            progress.current.fail.push(level)
            if (progress.current.fail.length > 1 
                && !progress.current.success 
                && progress.current.startLevel) {
                    setLevel(Math.floor(level - 1))
                    return
            }
            setLevel(level + .1)
        }
    }, [puzzleResolved])
    return (
        <article className="onboarding_step step-3">
            {selectedPuzzle ? <Puzzle /> : null}
        </article>
    )
}

export const OnboardingStepFour = (props: {confirm: (c: number) => {}}) => {
    const user = useSelector(selectUserWithProps(['language']))
    const i18n = I18n[user.language.slice(0, 2)] || I18n.en
    const navidate = useNavigate()
    const handleConfirm = () => {
        props.confirm(4)
        setTimeout(() => navidate(`../${RoutePaths.profile}`))
    }
    return (
        <article className="onboarding_step step-4">
            <button 
                type="submit" 
                onClick={handleConfirm} 
                className="confirm-btn"
            >
                    {i18n.stepLastConfirm}
            </button>
        </article>
    )
}


export const OnboardingStepFive = (props: {confirm: () => {}}) => {
    // return (
    //     <article className="onboarding_step  step-4">
    //         <h3></h3>
    //         <button type="button" className="skip-btn" onClick={skip}></button>
    //     </article>
    // )
}

export const Steps = (props: {step: number, confirm: () => {}}) => {
    const {step, confirm} = props
    return [
        <OnboardingStepOne confirm={confirm} />,
        <OnboardingStepTwo confirm={confirm} />,
        <OnboardingStepThree confirm={confirm} />,
        <OnboardingStepFour confirm={confirm} />,
        // <OnboardingStepFour confirm={confirm} />,
        // <OnboardingStepFive confirm={confirm} />
    ][step]
}

export default Steps
