import { BaseBoardSize, MPS, TPS } from '../constants/gameConstants'
import {
    EngineBoard,
    GameVariant,
    MMRResult,
    Move,
    PieceColor,
    MMStepProps,
    IBoardPieces,
    PieceType,
    IKingDiagonalPoint,
    IKingDiagonal,
    IKingDiagonals,
    // GamePiece,
    IBoardTower,
    ITower,
    IBoardPiece,
} from '../models/models'
import {
    copyObj,
    oppositeDirection,
    oppositeColor,
    filterKingMoves,
    splitMove,
    // positionSummary,
    Tower
} from './gameplay-helper-fn'
import {
    createEngineBoard,
    getDirection,
} from './board-helper-fn'

export class BaseMoveResolver {
    GV: GameVariant = 'towers'
    size: number = BaseBoardSize
    board = createEngineBoard(BaseBoardSize) as EngineBoard
    constructor(props: any) {
        this.GV = props.GV || 'towers'
        this.size  = props.size || BaseBoardSize
        this.board = createEngineBoard(BaseBoardSize) as EngineBoard
    }
    
    setProps = (props: { GV: GameVariant; size?: number }) => {
        this.GV = props.GV
        this.size = props.size || (props.GV === 'international' ? 10 : BaseBoardSize)
        this.board = createEngineBoard(this.size)
    }

    checkPieceTypeChanging(
        to: string,
        color: PieceColor,
        type: PieceType
    ): PieceType {
        if (
            (parseInt(to.slice(1)) === this.size &&
                color === PieceColor.white) ||
            (parseInt(to.slice(1)) === 1 && color === PieceColor.black)
        ) {
            return PieceType.king
        }
        return type
    }

    captureTower = (tower: IBoardTower): IBoardTower | null => {
        if (this.GV !== 'towers') {
            return null as unknown as IBoardTower
        }
        const { color, DOM } = tower
        const oppoColor = oppositeColor(color)
        const towerExist = (tower[oppoColor] && tower[oppoColor] > 0) || tower[color] > 1
        if (!towerExist) return null as unknown as IBoardTower
        const nTower = Tower({
            [oppoColor]: tower[oppoColor],
            color: tower[color]! > 1 ? color : oppoColor,
            [color]: tower[color]! - 1,
            type: PieceType.man,
        }) as IBoardTower
        if (DOM) {
            nTower.DOM = DOM
        }
        return nTower
    }
}

export class KingMoveResolver extends BaseMoveResolver {

    getMoveTakenPieces(move: string, pos: IBoardPieces, color: PieceColor) {
        if (!move.includes(TPS)) return null as unknown as string[]
        const m = move.split(TPS)
        let takenPieces = [] as string[]
        let i = 1
        while (i < m.length) {
            const dir = getDirection(m[i-1], m[i])
            const {taken} = this.getKingDiagonal(dir, {point: m[i-1], color}, pos, [], m[i])
            takenPieces = takenPieces.concat(taken)
            i += 1
        }
        return takenPieces
    }

    makeMove = (props: MMStepProps) => {
        const { takenPieces, move, startPos } = props as MMStepProps
        return!takenPieces
            ? this.makeFreeMove(move[0], move[1], startPos)
            : this.makeMandatoryMove({ move, startPos, takenPieces })
    }

    makeMoves(moves: string[], position: IBoardPieces): IBoardPieces {
        let moveProps = {
            move: splitMove(moves[0]),
            startPos: position
        } as MMStepProps
        let color = position[moveProps.move[0]]?.color 
        if (!color) {
            console.error('invalid props to make moves', moves, position)
            return position
        }
        moveProps.takenPieces = this.getMoveTakenPieces(moves[0], position, color)
        for (const move of moves.slice(1)) {
            color = oppositeColor(color)
            moveProps.startPos = this.makeMove(moveProps)
            moveProps.move = splitMove(move)
            moveProps.takenPieces = this.getMoveTakenPieces(move, moveProps.startPos, color)
        }
        return this.makeMove(moveProps)
    }

    makeFreeMove(from: string, to: string, _towers: IBoardPieces): IBoardPieces {
        const towers = copyObj(_towers)
        if (!towers[from]) {
            console.error('makeFreeMove invalid props', from, to, towers)
        }
        const tower = copyObj(towers[from])
        tower.type = this.checkPieceTypeChanging(
            to,
            tower.color,
            tower.type
        )
        towers[to] = tower
        delete towers[from]
        return towers
    }

    makeMandatoryMove = (move: MMStepProps): IBoardPieces => {
        if (move.move.length === 2) {
            const res = this.makeMandatoryMoveStep(move, true)
            return res
        }
        const firstStepProps = { ...move, move: move.move.slice(0, 2) }
        const nextMoveProps = {
            move: move.move.slice(1),
            startPos: this.makeMandatoryMoveStep(firstStepProps),
            takenPieces: move.takenPieces?.slice(1),
        }
        return this.makeMandatoryMove(nextMoveProps)
    }

    makeMandatoryMoveStep = (move: MMStepProps, last = false): IBoardPieces => {
        if (this.GV !== 'towers') {
            return this.makePieceMandatoryStep(move, last)
        }
        const towers = copyObj(move.startPos!)
        const [from, to] = [move.move[0], move.move[1]]
        const tower = towers[from]
        const takenPiece = move.takenPieces![0]
        const newMiddleTower = this.captureTower(towers[takenPiece])   
        if (tower.color === PieceColor.white) {
            tower.black += 1
        } else {
            tower.white += 1
        }
        delete towers[from]
        if (!newMiddleTower) {
            delete towers[takenPiece]
        } else {
            towers[takenPiece] = newMiddleTower
        }
        tower.type = this.checkPieceTypeChanging(
            to,
            tower.color,
            tower.type
        )
        towers[to] = tower as IBoardTower
        if (tower.white + tower.black < 2) {
            console.error('mand move error', tower, move)
            throw new Error('invalid move')
        }
        return towers
    }

    makePieceMandatoryStep = (
        move: MMStepProps,
        last = false
    ): IBoardPieces => {
        const pieces = copyObj(move.startPos!)
        const [from, to] = move.move
        const piece =  pieces[from] as IBoardPiece
        delete pieces[from]
        delete pieces[move.takenPieces![0]]
        if (this.GV !== 'international' || last) {
            piece.type = this.checkPieceTypeChanging(
                to,
                piece.color,
                piece.type
            )
        }
        pieces[to] = piece
        return pieces
    }

    getKingDiagonal(
        dir: string,
        st: {point: string, color: PieceColor}, 
        pos: IBoardPieces, 
        excluded = [] as string[],
        limit?: string
    ): IKingDiagonal {
        const diagonal = {
            dir, 
            line: [{point: st.point, piece: pos[st.point]}], 
            taken: []
        } as IKingDiagonal
        let cell = this.board[st.point]
        while(cell) {
            const key = cell.neighbors[dir]
            if (key === limit) {
                return diagonal
            }
            if (!key) return diagonal
            if (!pos[key]) {
                diagonal.line.push({point: key, piece: null as unknown as IBoardTower})
            }
            cell = this.board[key]
            if (pos[key]) {
                
                if (excluded.includes(key) || pos[key].color === st.color) {
                    return diagonal
                }
                const nextKey = cell.neighbors[dir]
                if (pos[nextKey] || !nextKey) {
                    return diagonal
                }
                diagonal.line.push({point: key, piece: pos[key]})
                diagonal.line.push({point: nextKey, piece: null as unknown as ITower})
                diagonal.taken.push(key)
                cell = this.board[nextKey]
                if (nextKey === limit) {
                    return diagonal
                }
            }
        }
        return diagonal
    }

    getKDiagonals(
        st: {point: string, color: PieceColor}, 
        pos: IBoardPieces, 
        exDir: string[] = [],
        taken: string[] = []
    ) {
        let diagonals = {} as IKingDiagonals
        let mandatory = false
        if (!this.board[st.point]) {
            console.error('invalid props to get diagonals', st)
        }
        for (const dir in this.board[st.point].neighbors) {
            if (exDir.includes(dir)) continue
            const diagonal = this.getKingDiagonal(dir, st, pos, taken)
            if (diagonal.taken.length) {
                if (!mandatory) {
                    diagonals = {}
                    mandatory = true
                }
                diagonals[dir] = diagonal
            } else if (diagonal.line.length > 1 && !mandatory) {
                diagonals[dir] = diagonal
            }
        }
        return {diagonals, mandatory}
    }

    getAthwartDiagonals(
        st: {point: string, color: PieceColor},
        pos: IBoardPieces, 
        exDir: string, 
        taken: string[] = []
    ) {
        const oppoDir = oppositeDirection(exDir)
        if (!st.point) {
            console.error('invalid props to get athw diags', st, taken, exDir)
        }
        return this.getKDiagonals(st, pos, [exDir, oppoDir], taken)
    }

    getKingMoves(point: string, position: IBoardPieces, col?: PieceColor, deb = false): MMRResult[] {
        const color = col || position[point]?.color
        if (!color) {
            console.error('invalid props to get king moves', point, position)
            return []
        }
        const {diagonals, mandatory} = this.getKDiagonals({point, color}, position)
        if (!mandatory) {
            let moves = [] as MMRResult[]
            for (const dir in diagonals) {
                if (dir === 'mand') continue
                const line = diagonals[dir].line as IKingDiagonalPoint[]
                moves = moves.concat(line.slice(1).map(p => {
                    const move = [line[0].point, p.point]
                    const endPos = this.makeFreeMove(move[0], move[1], position)
                    return {move, endPos}
                }))
            }
            return moves
        }
        const moves = this.getMKingMoves(diagonals, position, color)
        return filterKingMoves(moves)
    }

    getMKingMoves(
        diags: IKingDiagonals, 
        pos: IBoardPieces, 
        color: PieceColor,
        oldTaken: string[] = [],
        MMResult = true,
    ): MMRResult[] {
        let moves = [] as MMStepProps[]
        for (const dir in diags) {
            const {line, taken} = diags[dir]
            const takenPieces = oldTaken.slice()
            const move = [line[0].point]
            const index = line.findIndex(p => p.point === taken[0])
            if (takenPieces.includes(line[index].point)) {
                continue
            }
            move.push(line[index + 1].point)
            takenPieces.push(line[index].point)
            const {
                diagonals: athwDiags, mandatory
            } = this.getAthwartDiagonals({point: move[1], color}, pos, dir, takenPieces)
            const rest = {
                dir, 
                line: diags[dir].line.slice(index + 1), 
                taken: diags[dir].taken.slice(1)
            }
            const contMove = {
                move: move.slice(), takenPieces: takenPieces.slice(), startPos: pos 
            }
            if (mandatory) {
                for (const _dir in athwDiags) {
                    moves = moves
                        .concat(this.getMKMovesNext(athwDiags[_dir], contMove, color))
                } 
            } 
            if (taken.length > 1) {
                moves = moves.concat(this.getMKMovesNext(rest, contMove, color, true))
            }  
            if (taken.length === 1) {
                if (!mandatory) {
                    moves.push({move,  takenPieces, startPos: pos})
                }
                const contMove = {
                    move: [move[0]], takenPieces: takenPieces.slice(), startPos: pos
                }
                moves = moves.concat(this.checkRestDiagonal(rest, contMove, color))
            }
        }
        return moves.map(m => ({
            move: m.move,
            takenPieces: m.takenPieces,
            endPos: MMResult ? this.makeMandatoryMove(m) : m.startPos
        }))
    }

    checkRestDiagonal(diag: IKingDiagonal, mov: MMStepProps, color: PieceColor): MMStepProps[] {
        let moves = [] as MMStepProps[]
        const {move, startPos, takenPieces} = mov
        const {line, dir} = diag
        for (const p of line.slice(1)) {
            const st = {point: p.point, color}
            if (!st.point) {
                console.error('inv props check rest', diag, mov)
            }
            const athwDiags = this.getAthwartDiagonals(st, startPos, dir, takenPieces)
            const _move = move.slice().concat(p.point)
            if (!athwDiags.mandatory) {
                moves.push({ move: _move, takenPieces, startPos })
                continue
            }
            const contMove = {move: _move, startPos, takenPieces: takenPieces.slice()}
            for (const dir in athwDiags.diagonals) {
                const diag = athwDiags.diagonals[dir]
                moves = moves.concat(this.getMKMovesNext(diag, contMove, color))
            }
        }
        return moves
    }

    getMKMovesNext(
        diag: IKingDiagonal, 
        mov: MMStepProps, 
        color: PieceColor, 
        conti = false
    ): MMStepProps[] {
        let moves = [] as MMStepProps[]
        const {startPos} = mov
        const move = copyObj(mov.move), takenPieces = copyObj(mov.takenPieces)
        const {line, taken, dir} = diag
        const index = line.findIndex(p => p.point === taken[0])
        if (index < 0 || mov.takenPieces.includes(diag.taken[0])) {
            index < 0 && console.error('invalid diag', diag, mov)
            return []
        }
        if (conti) {
            for (let i = 1; i < index; i++) {
                const point = {point: line[i].point, color}
                const athwDiags = this.getAthwartDiagonals(point, startPos, dir, takenPieces)
                if (!athwDiags.mandatory) continue
                const contMove = {
                    move: move.slice(0, -1).concat(point.point),
                    startPos,
                    takenPieces: takenPieces.slice(),
                }
                for (const dir in athwDiags.diagonals) {
                    const diag = athwDiags.diagonals[dir]
                    moves = moves.concat(this.getMKMovesNext(diag, contMove, color))
                }
            }
        }
        move.push(line[index + 1].point)
        takenPieces.push(line[index].point)
        const rest = {dir, line: line.slice(index + 1), taken: taken.slice(1)}
        const point = {point: line[index + 1].point, color}
        if (!point.point) {console.error('invalid diag', diag)}
        const athwDiags = this.getAthwartDiagonals(point, startPos, dir, takenPieces)
        const resMove = {move: move.slice(), takenPieces: takenPieces.slice(), startPos}
        if (athwDiags.mandatory) {
            for (const dir in athwDiags.diagonals) {
                const diag = athwDiags.diagonals[dir]
                moves = moves.concat(this.getMKMovesNext(diag, resMove, color))
            }
        }
        if (rest.taken.length) {
            moves = moves.concat(this.getMKMovesNext(rest, resMove, color, true))
        } else {
            const contMove = { move: move.slice(0, -1), startPos, takenPieces }
            moves = moves.concat(this.checkRestDiagonal(rest, contMove, color))
            if (!athwDiags.mandatory && !rest.taken.length) {
                moves = moves.concat(resMove)
            }
        }   
        return moves
    }

    getNewKingMoves(props: MMStepProps, color: PieceColor, exDir: string) {
        const { move,  startPos, takenPieces } = props
        const point = move[move.length - 1]
        const {diagonals, mandatory} = this.getKDiagonals({point, color}, startPos, [exDir])
        if (!mandatory) return props
        const kingMoves = this.getMKingMoves(diagonals, startPos, color, takenPieces, false)
        const res = filterKingMoves(kingMoves).map(m => ({
            move: move.concat(m.move.slice(1)),
            startPos,
            takenPieces: m.takenPieces!
        }))
        return res                 
    }
}

class MoveResolver extends KingMoveResolver {

    getPieceMoves(towers: IBoardPieces, key: string): MMRResult[] {
        return towers[key].type === PieceType.king
            ? this.getKingMoves(key, towers, towers[key].color)
            : this.getManMoves(key, towers)
    }

    getPossibleMoves(pos: IBoardPieces, turn: PieceColor, del = false): MMRResult[] {
        let mandatory = [] as MMRResult[],
            free = [] as MMRResult[]
        const towers = del ? copyObj(pos) : pos
        for (const key in towers) {
            if (del && towers[key].mandatory) {
                delete towers[key].mandatory
            }
            if (towers[key].color !== turn) {
                continue
            }
            const moves = this.getPieceMoves(towers, key)
            if (moves[0]?.takenPieces?.length) {
                mandatory = mandatory.concat(moves)
            } else if (!mandatory.length) {
                free = free.concat(moves)
            }
        }
        return mandatory.length ? mandatory : free
    }

    getPossibleMovesForTree(props: MMRResult[]): Move[] {
        return props.map(m => 
            ({move: m.move.join(m.takenPieces ? TPS : MPS), position: m.endPos}))
    }

    manMoveRestrictions(color: PieceColor) {
        return color === PieceColor.white
            ? ['rightDown', 'leftDown']
            : ['rightUp', 'leftUp']
    }

    getManMoves(key: string, towers: IBoardPieces): MMRResult[] {
        let mandatory = [] as MMStepProps[]
        let free = [] as MMRResult[]
        const color = towers[key].color
        const cell = this.board[key]
        if (!cell) {
            console.error(key, this.board, this.size, towers)
            return []
        }
        const directions = Object.keys(cell.neighbors)
        for (let dir of directions) {
            const res = this.checkManDirection(dir, key, color, towers)
            if (!res.taken.length 
                && res.move.length 
                && !this.manMoveRestrictions(color).includes(dir)) {
                free = free.concat({
                    move: res.move,
                    endPos: this.makeFreeMove(res.move[0], res.move[1], towers)
                })
            } else if (res.taken.length) {
                const contMove = {
                    move: res.move,
                    startPos: towers,
                    takenPieces: res.taken
                }
                const oppoDir = oppositeDirection(dir)
                mandatory = res.type === PieceType.man 
                    ? mandatory.concat(this.getManMandatoryMoves(contMove, color, oppoDir))
                    : mandatory.concat(this.getNewKingMoves(contMove, color, oppoDir))
            }
        }
        const result = mandatory.length 
            ? mandatory.map(m => ({
                move: m.move, 
                takenPieces: m.takenPieces, 
                endPos: this.makeMandatoryMove(m)
            }))
            : free
        return result
    }

    checkManDirection(dir: string, point: string, color: PieceColor, position: IBoardPieces) {
        const neiCell = this.board[point].neighbors[dir]
        if (position[neiCell]?.color === oppositeColor(color)) {
            const nextCell = this.board[neiCell] && this.board[neiCell].neighbors[dir]
            if (nextCell && !position[nextCell]) {
                const type = this.checkPieceTypeChanging(nextCell, color, PieceType.man)
                return {taken: [neiCell], move: [point, nextCell], type}
            }
        }
        return {taken: [], move: !position[neiCell] ? [point, neiCell] : []}
    }

    getManMandatoryMoves(
        props: MMStepProps, 
        color: PieceColor, 
        exDir: string,
    ): MMStepProps[] {
        let moves = [] as MMStepProps[]
        const point = props.move[props.move.length - 1]
        const {takenPieces, startPos} = props
        let mandatory = false
        for (const d in this.board[point].neighbors) {
            if (d === exDir) continue
            const res = this.checkManDirection(d, point, color, startPos)
            if (res.taken.length 
                && !res.taken.filter(p => takenPieces.includes(p)).length) 
            {
                mandatory = true
                const contMove = {
                    move: props.move.slice().concat(res.move[1]),
                    startPos,
                    takenPieces: takenPieces.slice().concat(res.taken)
                }
                const oppoDir = oppositeDirection(d)
                moves = res.type === PieceType.man 
                    ? moves.concat(this.getManMandatoryMoves(contMove, color, oppoDir))
                    : moves.concat(this.getNewKingMoves(contMove, color, oppoDir))
            }
        }
        if (!mandatory) {
            return [props]  
        }
        return moves
    }
}

export const moveResolver =  new MoveResolver({size: BaseBoardSize, GV: 'towers'})

export default MoveResolver
