import { useDispatch, useSelector } from "react-redux"
import { selectUserWithProps, selectWithProps } from "../../store/rootState&Reducer"
import { TopState } from "../../constants/gameConstants"
import { IPuzzle, IRootState } from "../../models/models"
import { PuzzleBoard } from "../../UI-components/Board/PuzzleBoard"
import storage from "../../common/storage"
import { choosePuzzle, selectWindowSize, setPuzzles } from "../../store/topStateSlice"
import { clearResolved, selectToken } from "../../store/userSlice"
import { useState, useEffect } from "react"
import { I18n } from "../../assets/i18n"
// import { createCellsMap, updateCellsMap, updatePiecesPosition } from "../../local-engine/board-helper-fn"


const PuzzleAdmin = () => {
    const {puzzlesAdmin, resolvedPuzzles} = useSelector(selectUserWithProps(
        ['puzzlesAdmin', 'resolvedPuzzles'])
    ) as any
    const { puzzles } = useSelector(selectWithProps(['puzzles'], TopState))
    const dispatch = useDispatch()
    const viewAll = () => {
        const all = storage.getPuzzles().length 
            ? storage.getPuzzles()
            : require('../../assets/basicPuzzles.json')
        console.log('all', all)
        const puzz = puzzles.length <= 6
            ? all
            : puzzles.filter((p: IPuzzle) => !resolvedPuzzles.includes(p._id)).slice(0, 6)
        dispatch(setPuzzles(puzz))
    }
    const getBasic = () => {
        const puzz = require('../../assets/basicPuzzles.json')
            .slice().sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
        dispatch(setPuzzles(puzz))
    }
    const getStored = () => {
        dispatch(setPuzzles(storage.getPuzzles()))
    }
    if (!puzzlesAdmin) return null
    return  (
        <div className="puzz-admin">
            <button
                className="ctrl-btn" 
                type="button" 
                onClick={() => dispatch(clearResolved())}>
                Clear resolved
            </button>
            <button className="ctrl-btn" type="button" onClick={viewAll}>
                {puzzles.length <= 6 ? 'view all' : 'view 6'}
            </button> 
            <button className="ctrl-btn" type="button" onClick={getBasic}>
                {'Get basic puzzles'}
            </button>
            <button className="ctrl-btn" type="button" onClick={getStored}>
                {'Get stored puzzles'}
            </button>
        </div>
    )
}

export const PuzzlesMenu = () => {
    const {width} = useSelector(selectWindowSize)
    const { puzzles } = useSelector(selectWithProps(['puzzles'], TopState))
    const admin = useSelector((state: IRootState) => (state.user as any).puzzlesAdmin)
    const {language, token} = useSelector(selectUserWithProps(['token', 'language']))
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const dispatch = useDispatch()
    const onClick = (id: string) => {
        dispatch(choosePuzzle(id))
    }
    const [cellBase, setCellBase] = useState(30)
    useEffect(() => {
        if (width > 1200) {
            setCellBase(30)     
        } else {
            setCellBase(25)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
    const sortedPuzzles = [...puzzles].sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
    if (!sortedPuzzles.length && !token) {
        return <h2>{i18n.signToGetPuzzles}</h2>
    }
    return (
        <>
            <PuzzleAdmin />
            <div className="puzzles-menu">
            {
                sortedPuzzles.map((p: IPuzzle) => {
                    return (
                        <button
                            className="puzzles-menu_item"
                            key={p._id} 
                            type="button" 
                            onClick={() => onClick(p._id)}>
                            <span className="title">
                                {p.description || p.name}
                                &nbsp;
                                { admin ? p.level : ''}
                            </span>
                            <PuzzleBoard puzzle={p} cellBase={cellBase} />
                        </button>
                    )
                })
            }
            </div>
        </>
    )
}