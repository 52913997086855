import { createSlice } from "@reduxjs/toolkit";

import { IGame, PieceColor, IRootState, IGoTo } from "../models/models";


export const InitialGame: IGame = {
    turn: PieceColor.white,
    nextMoves: [],
    moves: [],
    position: {},
    lastMove: {} as IGoTo,
    moveStep: 0,
    idleInRow: 0,
    hintMove: '',
    startPosition: {},
    helpRequest: false
}

const gameSlice = createSlice({
    name: 'game',
    initialState: InitialGame,
    reducers: {
        setNextMoves(state, action) {state.nextMoves = action.payload},
        updateGame(state, action) {return {...state, ...action.payload}},
        updatePieces(state, action) {
            for(const key in action.payload) {
                state.position[key] = action.payload[key]
            }
        },
        updatePieceDOM(state, action) {
            if (state.position[action.payload.key]) {
                state.position[action.payload.key].DOM = action.payload.DOM
            } else {
                console.error(action.payload, state.position, Object.keys(state.position))
            }
        },
        playMoves(state) {},
        setHintMove(state, action) {state.hintMove = action.payload},
        help(state, action) {state.helpRequest = action.payload},
        setStartPosition(state, action) {state.startPosition = action.payload},
        repeatLastMove(state) {},
        idling(state, action) {state.idleInRow = action.payload},
        updatePosition(state, action) {state.position = action.payload},
        updateMoves(state, action) {state.moves = action.payload},
        setTurn(state, action) {state.turn = action.payload},
        goToPosition(state, action) {state.lastMove = action.payload},
        makeMove(state, action) {},
        setMoveStep(state, action) {state.moveStep = action.payload},
        tryUpdatePosition(state, action) {}
    }
})

export const {
    goToPosition,
    setMoveStep,
    makeMove,
    setNextMoves,
    setTurn,
    playMoves,
    updateMoves,
    updatePieces,
    updateGame,
    updatePosition,
    idling,
    repeatLastMove,
    help,
    setHintMove,
    setStartPosition,
    updatePieceDOM,
    tryUpdatePosition,
} = gameSlice.actions

export const selectGame = (state: IRootState) => state.game

// export const selectLastMove = (state: IRootState) => state.game.lastMove

export const {actions} = gameSlice

export default gameSlice.reducer
