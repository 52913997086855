import { createSlice } from "@reduxjs/toolkit";
import { ITopState, AppType, IRootState } from "../models/models";
import { DefMessage } from "../hooks/useShowMessage";

export const AppInitialState: ITopState = {
    windowSize: {width: window?.innerWidth || 100, height: window?.innerHeight || 100},
    type: AppType.game,
    wsConnectionState: 0,
    wsMessage: null,
    backMessage: DefMessage,
    localEngine: false,
    puzzles: [],
    selectedPuzzle: '',
    solution: [],
    puzzleResolved: null,
    appModeUser: true
}

const appSlice = createSlice({
    name: 'app',
    initialState: AppInitialState,
    reducers: {
        setAppType(state, action) {state.type = action.payload},
        setAppState(state, action) { return {...state, ...action.payload} },
        setSolution(state, action) {state.solution = action.payload},
        choosePuzzle(state,action) {state.selectedPuzzle = action.payload},
        getPuzzles() {},
        setAppMode(state, action) {state.appModeUser = action.payload},
        savePuzzle(state, action) {}, 
        setPuzzles(state, action){state.puzzles = action.payload},
        setBackMessage(state, action) {state.backMessage = action.payload},
        setWindowSize(state, action) { return {...state, windowSize: action.payload} },
        makePMove() {},
        setConnectionState(state, action) {state.wsConnectionState = action.payload},
        getBasicPuzzles() {},
        setPuzzleStatus(state, action) {state.puzzleResolved = action.payload},
        startEngine(state, action) {state.localEngine = action.payload},
        sendWsMessage(state, action) {state.wsMessage = action.payload},
        skipPuzzle(state) {},
    }
})

export const {actions} = appSlice
export const {
    setAppType, 
    setWindowSize, 
    sendWsMessage, 
    setBackMessage, 
    startEngine,
    setAppState,
    getPuzzles,
    setPuzzles,
    choosePuzzle,
    setSolution,
    makePMove,
    savePuzzle,
    setPuzzleStatus,
    getBasicPuzzles,
    setConnectionState,
    skipPuzzle,
    setAppMode
} = actions

export default appSlice.reducer

export const selectWindowSize = (state: IRootState) => state.topState.windowSize
export const selectAppType = (state: IRootState) => state.topState.type
