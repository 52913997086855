import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './waiting-rival.scss';
import {cancelChallenge} from '../../store/gameStateSlice'
import { GameSettings, Interval } from '../../constants/gameConstants';
import { selectLanguage } from '../../store/userSlice';
import { I18n } from '../../assets/i18n';
import { sendWsMessage } from '../../store/topStateSlice';
import { selectGameStateWithProps, selectUserWithProps, selectWithProps } from '../../store/rootState&Reducer';
import { MdCancel } from 'react-icons/md';
// import { selectBoard } from '../../store/rootState&Reducer';

const WaitingRival = () => {
    const {gameVariant: GV, timing} = useSelector(
        selectWithProps(['gameVariant', 'timing'], GameSettings)
    )
    const {boardSize: bSize} = useSelector(selectWithProps(['boardSize'], 'board'))
    const {userId} = useSelector(selectUserWithProps(['userId']))
    const dispatch = useDispatch()
    const {waitingForRival} = useSelector(selectGameStateWithProps(['waitingForRival']))
    if (!waitingForRival) return null
    const handleCancel = () => {
        dispatch(cancelChallenge())
        const payload = {userId, GV, timing: `${timing.gameTimeLimit}/${timing.adds}`, bSize}
        dispatch(sendWsMessage({message: 'cancel challenge', payload}))
    }
    return (
        <> 
            <div className="waiting-rival-spinner" onClick={handleCancel}>
                <div title="cancel" className="logo"></div>
                <MdCancel />
            </div>
            <Timer />
            <div className="waiting-rival-back"></div>
        </>
    );
};

export default WaitingRival;


const int = {data: null as any}

const Timer = () => {
    const lang = useSelector(selectLanguage).slice(0, 2)
    const i18n = I18n[lang.slice(0, 2)] || I18n.en
    const title1 = i18n.timer1 || 'expected time:'
    const title2 = i18n.timer2 || 'passed:'
    const unit = i18n.timerUnit || 'sec'
    const [timer, setTimer] = useState(0)
    useEffect(() => {
        int.data = setInterval(() => setTimer((timer) => (timer + 1)), Interval)
        return () => { clearInterval(int.data) }
    }, []) 
    return <div className="waiting-rival-timer">
            <p><span>{title1}</span><span>{10}&nbsp;{unit}</span></p>
            <p><span>{title2}</span><span>{timer}&nbsp;{unit}</span></p>
        </div>
}
