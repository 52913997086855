import { Suspense, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {  MdOutlinePauseCircle, MdOutlinePlayCircle } from "react-icons/md";

import { RoutePaths, BaseBoardSize } from '../../constants/gameConstants'
import Spinner from '../../UI-components/Spinners/Spinner'
import BGBoard from './Bg-board'
import { I18n } from '../../assets/i18n'
import { setName } from '../../store/userSlice'
import { AnimatedBoard } from '../../UI-components/Board/AnimatedBoard'
import { selectWindowSize } from '../../store/topStateSlice';
import { selectUserWithProps } from '../../store/rootState&Reducer';

import './home.scss'

export const Home = () => {
    const {
        viewMode: mode, theme, name, language
    } = useSelector(selectUserWithProps(['token', 'viewMode', 'theme', 'name', 'exp', 'language']))
    const winSize = useSelector(selectWindowSize)
    const navi = useNavigate()
    const dispatch = useDispatch()
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const [pause, setPause] = useState(false)
    const [cS1, setSc1] = useState(0)

    const createGuest = () => {
        const name = `Guest-${Math.round(Math.random()*100)}`
        dispatch(setName(name))
    }
    useEffect(() => {
        const invite = document.querySelector('.invite_text')
        if (!invite) return 
        invite.innerHTML = i18n.invite || I18n.en.invite
        const clickBtn = (e: any) => {
            e.preventDefault()
            const classList = (e.target as Element).classList
            console.log(classList)
            if (classList.contains('sign-btn')) {
                navi('auth')
            }
            if (classList.contains('guest-btn')) {
                createGuest()
            }
        }
        const buttons = invite.querySelectorAll('button')
        buttons.forEach(b => b.addEventListener('click', clickBtn))
        console.log(buttons)
        return () => {
            buttons.forEach(b => b.removeEventListener('click', clickBtn))
        }
    })

    useEffect(() => {
        if (winSize.width < 560) {
            cS1 !== 35 && setSc1(35)
        } else if (winSize.width >= 560 && winSize.width < 960) {
            cS1 !== 30 && setSc1(30)
        } else if (winSize.width >= 960 && winSize.width < 1200) {
            cS1 !== 40  && setSc1(40)
        } else if (winSize.width >= 1200) {
            cS1 !== 45 && setSc1(45)
        }           
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winSize])

    return (
        <Suspense fallback={<Spinner />}>
            <div className={`page-wrapper noselect dh-${cS1 * BaseBoardSize}`}>
                {<><BGBoard /></>}
                <section className={`about-page mode-${mode} theme-${theme}`}>
                    {!name ? <h1>{i18n.about_title}</h1> : null}
                    <div className='home-content-wrapper'>
                        {
                            !name
                            ? <>
                                <div className='description-wrapper'>
                                    <button
                                        type='button' 
                                        className="game-example-wrapper"
                                        onClick={() => setPause(!pause)}
                                    >
                                        <AnimatedBoard stop={pause} cS={cS1} />
                                        <div className='example-ctrl-btn'>
                                            {!pause ? <MdOutlinePauseCircle /> : <MdOutlinePlayCircle />}
                                        </div>
                                    </button>
                                    <div className='game-description'>{i18n.about_start}</div>
                                </div>
                                <div className='invite'>
                                    <div className='invite_text'>
                                        
                                    </div>
                                    <div className="invite_menu">
                                        <NavLink className='signin-link' to={RoutePaths.auth} >
                                            {i18n.signin || I18n.en.signin}
                                        </NavLink>
                                        <button 
                                            type='button' 
                                            className='guest-link' 
                                            onClick={createGuest}
                                        >
                                            {i18n.guest || I18n.en.guest}
                                        </button>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className={`home-menu theme-${theme}`}>
                                    <NavLink className='game-link' to={`../${RoutePaths.game}`} >
                                        <h4>{i18n.playGame}</h4> 
                                        <AnimatedBoard num={1} cS={cS1} />
                                    </NavLink>
                                    <NavLink className='puzzles-link' to={`../${RoutePaths.puzzles}`} >
                                        <h4>{i18n.puzzles}</h4>
                                        <AnimatedBoard num={0} cS={cS1} />
                                    </NavLink>
                                </div>
                            </>
                        }
                    </div>
                </section>
            </div>
        </Suspense>
    )
}

export default Home
