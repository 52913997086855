
import { Outlet, useLocation } from 'react-router-dom'
import './auth.scss'
import SigninPage from './SigninPage'
import { useSelector } from 'react-redux'
import { selectTheme, selectMode } from '../../store/userSlice'


const AuthPage = () => {
    const theme = useSelector(selectTheme)
    const mode = useSelector(selectMode)
    const location = useLocation()  
    return (
        <div className={`page-wrapper noselect mode-${mode} theme-${theme}`}>
            {location.pathname === '/auth' ? <SigninPage /> :  <Outlet />}
        </div>
    )
           
}

export default AuthPage
