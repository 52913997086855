export const BoardDirections = ['leftUp', 'leftDown', 'rightUp', 'rightDown'];
export const MainTree = 'tree'
export const ExtraTree = 'extraTree'
export const TPS = 'x'
export const MPS = '-'
export const DefaultAnalysisLevel = 5
export const EvValLim = 30
export const EngineMovesLimit = 5
export const MovesTailLim = 2
export const PlayerMovesLimit = 5
export const StandartTiming = [[1,0], [2,1], [3,0], [3,1], [5,0], [5,3], [10, 0], [10, 3], [15, 0]]
export const Languages = ['en', 'ru']
export const DefaultDepth = 4
export const ValueGap = .03
export const PieceCellRatio = .8
// export const MaxAdvancedDepth = 14//SK4KScBOrf34zkDH
export const TimeLimit = 2000
export const BreakLimit = 1.5
export const TopLegendValues = ['a','b','c','d','e','f','g','h','i','k','l','m']
export const SideLegendValues = new Array(12)
    .fill(0)
    .map((i: undefined, j: number): number => j + 1)
// export const BaseTransform = 'translate(-50%, -50%)'
export const DefaultTime = 300
export const MessageFadeTime = 10000
export const StorageName = 'check-tow'
export const PuzzleStorageName = 'check-tow-puzzles'
export const BaseBoardSize = 8
export const MaxBoardWidth = 600
export const Device = 900
export const InternationalBoardSize = 10
export const GVs = ['towers', 'russian', 'international']
export const ColorsToChoose = ['white', 'random', 'black']
export const OpponentTypes = ['engine', 'player']
export const EngineLevels = [50, 300, 550, 800, 1000, 1200]
export const CompetitionVariants = ['Casual', 'Ranked']
export const BPCs = ['black_gloss','black', 'darkgreen', 'darkblue', 'jam', 'amber']
export const WPCs = ['white_gloss', 'white', 'lime', 'sky', 'pink', 'tan']
export const Themes = ['tea', 'sea', 'chocko', 'mint', 'blush', 'rose']
export const StandardTiming = [
    [3, 2],
    [5, 0],
    [5, 3],
    [10, 0],
    [10, 5],
    [15, 0],
]
export const Board = 'board'
export const User = 'user'
export const Game = 'game'
export const TopState = 'topState'
export const GameSettings = 'gameSettings'
export const Analysis = 'analysis'
export const TimeForFirstMove = 15
export const Interval = 1000
export const BaseCellSize = 50
export const AnimationDuration = 300
export const CellTowerRatio = 0.7
export const DebutStage = 2
export const BPN = 12

export const GPClass = 'game-piece'
export const BoardCl = 'board__body'

export const sampleLinkEn = 'https://en.wikipedia.org/wiki/Bashni'
export const sampleLinkRu =
    'https://ru.wikipedia.org/wiki/%D0%A1%D1%82%D0%BE%D0%BB%D0%B1%D0%BE%D0%B2%D1%8B%D0%B5_%D1%88%D0%B0%D1%88%D0%BA%D0%B8'

export const MADelay = 500
export const RoutePaths = {
    onboarding: 'onboarding',
    home: '/',
    puzzles: 'puzzles',
    puzzle: ':id',
    onbStep: ":id",
    presets: 'presets',
    game: 'game',
    gameOfline: 'ofline/:gameId',
    gameOnline: 'online/:gameId',
    settings: 'settings',
    analysis: 'analysis',
    stats: 'stats',
    // rules: 'rules',
    auth: 'auth',
    signup: 'signup',
    email: 'email',
    reset: 'reset',
    profile: '/profile',
}

export const RouteTitles = {
    home: 'home',
    game: 'apps',
    analysis: 'zoom_in',
    stats: 'insert_chart',
    settings: 'settings',
    profile: 'person',
    auth: 'login',
}


export const WhitePCells8x8 = ['a1','a3','b2','c1','c3','d2','e1','e3','f2','g1','g3','h2']
export const BlackPCells8x8 = ['a7','b8','b6','c7','d8','d6','e7','f8','f6','g7','h8','h6']

export const WhitePCells = WhitePCells8x8.concat(['i1','i3','k2','b4','d4','f4','h4','k4'])
export const BlackPCells = BlackPCells8x8
   .filter((x) => !x.includes('6'))
   .concat(['i7','k10','k8','i9','a9','c9','e9','g9','b10','d10','f10','h10'])

export const getBlackPCells = (boardSize: number) => {
   return boardSize === 8 ? BlackPCells8x8 : BlackPCells
}

export const getWhitePCells = (boardSize: number) => {
   return boardSize === BaseBoardSize ? WhitePCells8x8 : WhitePCells
}

export const LargeDiagonal = ['a1', 'b2', 'c3', 'd4', 'e5', 'f6', 'g7', 'h8', 'i9', 'k10']

export const getBlackTowersCells = (boardSize: number): string[] => {
    return boardSize != InternationalBoardSize ? BlackPCells8x8 : BlackPCells
}

export const getWhiteTowersCells = (boardSize: number): string[] => {
    return boardSize != InternationalBoardSize ? WhitePCells8x8 : WhitePCells
}
export const DefaultData = { pieces: 0, kings: 0, towersT: 0, towersB: 0, moveNumber: 0, value: 0 }

export const FreeRoutes = ['/', '/game/presets', '/game/ofline', 'auth']
