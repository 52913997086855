/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

import {TowerComponent} from '../Tower/GamePiece'
import {BoardComponent} from './Board'
import { BaseBoardSize, MADelay } from '../../constants/gameConstants';
import { 
    calcPiecePosition,
    createDemoCellsMap, 
    createDemoStartPosition, 
    createStartPosition, 
    updatePiecesPosition 
} from '../../local-engine/board-helper-fn';
import { IDemo, Move, IBoardPieces as IBP, CellsMap } from '../../models/models';
import { Demos } from '../../constants/debutes-demos';
import { delay, splitMove } from '../../local-engine/gameplay-helper-fn';
import { selectUserWithProps } from '../../store/rootState&Reducer';

export const demoState = (n: number, cS: number):IDemo => ({
    ...createDemoStartPosition(cS),
    moves: Demos[n],
    moveNum: 0,
    demoStarted: false,
    move: [] as string[],
    bS: BaseBoardSize,
    mStep: 0 
})

export const AnimatedBoard = (props: {num?: number, stop?: boolean, cS?: number}) => {
    const {theme, pieceColors} = useSelector(selectUserWithProps(['theme', 'pieceColors']))
    const demoNum = (props.num || Math.floor(Math.random()*Demos.length))
    const [demo, setDemo] = useState(demoState(demoNum, props.cS || 30))
    const {position, bS, cS: cellSize} = demo
    const ref = useRef({nextPos: {} as IBP, transPos: {} as IBP})
    
    const makeDemoMoveFirstStep = async (_move: Move) => {
        if(props.stop || !_move) {
            return
        }
        const {move, position: pos} = _move as Move
        const moveArr = splitMove(move)
        const [from, to] = [moveArr[0], moveArr[moveArr.length - 1]]
        await delay(!demo.moveNum ? MADelay*2 : MADelay)
        const { position, cM, cS} = demo
        const nextPos = updatePiecesPosition(pos, cM, cS)
        const transPos = updatePiecesPosition(position, cM, cS)
        transPos[to] = transPos[from]
        delete transPos[from]
        ref.current = {transPos, nextPos}
        // console.log('demo move', moveArr, transPos, nextPos)
        setDemo(state => ({...state, position: transPos, mStep: 1, move: moveArr}))
    }

    const makeDemoMoveStep = async () => {
        const {move = [], mStep = 1} = demo
        const [from, to] = [move[mStep], move[move.length - 1]]
        await delay(MADelay)
        const { cM, cS} = demo
        const transPos = updatePiecesPosition(ref.current.transPos, cM, cS, [to])
        if (!transPos[to]) {
            console.error(transPos)
            return
        }
        transPos[to].DOM = calcPiecePosition(from, cM, cS)
        ref.current = {...ref.current , transPos}
        setDemo(state => ({...state, position: transPos, mStep: mStep + 1}))
    }

    const lastStep = async() => {
        const {moves, cM, cS} = demo
        const position = updatePiecesPosition(ref.current.nextPos, cM, cS)
        await delay(MADelay)
        const moveNum = (demo.moveNum + 1) % (moves.length + 1)
        setDemo(state => ({
            ...state, position, aStep: 0, mStep: 0, moveNum, move: []
        }))
    }

    const resetPosition = async() => {
        const {cM, cS} = demo
        await delay(MADelay*2)
        const stPos = createStartPosition(bS)
        const pos = updatePiecesPosition(stPos, cM, cS) as IBP
        setDemo(state => ({...state, position: pos, mStep: 0, moveNum: 0, move: []}))
    }

    const updateRef = (cM: CellsMap, cS: number) => {
        const {move = [], mStep = 0} = demo
        const nextPos = updatePiecesPosition(ref.current.nextPos || {}, cM, cS)
        const transPos = updatePiecesPosition(ref.current.transPos || {}, cM, cS)
        transPos[move[move.length - 1]].DOM = calcPiecePosition(move[mStep], cM, cS)
        ref.current = {nextPos, transPos}
    }

    useEffect(() => {
        const {stop, cS = 30} = props
        if (!demo.demoStarted && !stop) {
            setDemo(state => ({...state, demoStarted: true, cS}))
            makeDemoMoveFirstStep(demo.moves[demo.moveNum])
        }
    }, [])

    useEffect(() => {
        if (props.stop) {
            return
        }
        const {mStep = 0, move = [], moveNum, moves} = demo
        if (!moves[moveNum]) {
           resetPosition()
           return
        }
        if (mStep === 0 && !move.length ) {
            makeDemoMoveFirstStep(demo.moves[demo.moveNum])
        } else {
            if (mStep + 1 === move.length) {
                lastStep()
            } else {
                makeDemoMoveStep()
            }
        } 
    }, [demo.position])

    useEffect(() => {
        if (!props.stop) {
            makeDemoMoveFirstStep(demo.moves[demo.moveNum])
        }
    }, [props.stop])

    useEffect(() => {
        const {cM, cS} = createDemoCellsMap(props.cS || 30, demo.bS)
        const position = updatePiecesPosition(demo.position, cM, cS)
        if (demo.move?.length) {
            updateRef(cM, cS)                
        }
        setDemo(state => ({...state, position, cM, cS}))
    }, [props.cS])
    // console.log(props.cS, cellSize)
    const Towers = []
    for (const key in position) {
        Towers.push(<TowerComponent 
            key={key} 
            posKey={key} 
            gamePiece={position[key]} 
            view={'face'} 
            pieceColors={pieceColors} 
            cellSize={cellSize} 
        />)
    }
    const WrapperClass = `board__wrapper theme-${theme} demo-board`

    return (
        <div className={WrapperClass}>
            {Towers}
            <BoardComponent bs={bS} bw={bS*cellSize}/>
        </div>
    )  
}
