import React, { useEffect, useRef } from 'react'

import {BoardNotation, IPieces, PieceColor, PieceType} from '../../models/models'
import {TopLegendValues, SideLegendValues, BoardCl} from '../../constants/gameConstants'

import { useSelector } from 'react-redux';
import { selectUserWithProps, selectWithProps } from '../../store/rootState&Reducer';
import {TowerComponent} from '../Tower/GamePiece'

import './board.scss';
import { Cell } from './Board'


export const Board2x2 = () => {
    const {boardNotation} = useSelector(selectUserWithProps(['boardNotation', 'towerView']))

    const SL  = SideLegendValues.slice(0, 2).reverse()
    const TL = TopLegendValues.slice(0, 2)
    let k =  0
    const Board = SL.map((v: number, i: number) => {
        return TL.map((h: string, j: number) => {
            k = (i + j) % 2 ? k + 1 : k
            const type = !((i + j) % 2) ? 'light' : 'dark'
            const index = `${h}${v}`    
            const className = `board__cell ${type}`
            return (
                <Cell
                    key={i + '_' + j}
                    indexes={index} 
                    className={className}
                >
                    {
                        boardNotation === BoardNotation.dr && ((i + j) % 2)
                        ? <span className="board__cell-number">{k}</span> 
                        : null
                    }
                    {
                        boardNotation === BoardNotation.ch && !j
                        ? <span className="board__label-value ver">{SL[i]}</span>
                        : null
                    }
                    {
                        boardNotation === BoardNotation.ch && i + 1 === SL.length
                        ? <span className="board__label-value hor">{TL[j]}</span> 
                        : null
                    }
                </Cell>
            )
        })
    })
    const className = `${BoardCl} bs-${2}`        
    return (
         <div className={className}>
            {Board}
        </div>
    )  
}

const position = {
    a1: {color: PieceColor.white, white: 1, black: 1, type: PieceType.man, DOM: {x: 4, y: 58}},
    b2: {color: PieceColor.black, white: 1, black: 1, type: PieceType.man, DOM: {x: 55, y: 7}}
} as IPieces


export const DemoBoard = () => {
    const {
        pieceColors, theme, viewMode: mode, towerView: view
    } = useSelector(selectUserWithProps(['pieceColors', 'theme', 'viewMode', 'towerView']))
    const ref = useRef(null);
    const WrapperClass = `board__wrapper mode-${mode} theme-${theme} demo-board`
    const Towers = []
    // const cellsMap = createCellsMap(2, 40)
    for (const key in position) {
        Towers.push(<TowerComponent 
                        key={key} 
                        posKey={key}
                        gamePiece={position[key]} 
                        view={view} 
                        pieceColors={pieceColors}
                        cellSize={50}
                    />)
    }

    useEffect(() => {
        if (ref.current) {
            return
        }
        const elB = ref.current as unknown as HTMLElement
        const board = elB.getBoundingClientRect()
        console.log(board)
    }, [])

    return (
        <>
            <section className={WrapperClass} ref={ref}>
                {Towers}
                <Board2x2 />
            </section>
        </>
    )
}
