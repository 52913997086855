export const I18n: { [key: string]: any } = {
    en: {
        homeTitle: 'Home',
        gameTitle: 'Game',
        analyzeTitle: 'Analysis',
        shortMainTitle: 'Checkers',
        mainTitle: 'Checkers Towers',
        newGame: 'New game',
        submitLog: 'Login',
        submitReg: 'Registration',
        logTitle: 'Login',
        regTitle: 'Registration in',
        logMess: 'Please enter Nickname or email and password',
        regMess: 'Please fill registration form',
        modeButtonLog: 'Do not have registered yet?',
        modeButtonReg: 'Login',
        labelPassword: 'Password',
        labelNickname: 'Nickname',
        submitButton: 'Submit',
        about_title: "Welcome to Checkers Towers!",
        about_start: "Checkers Towers is one of the most complex and beautiful checkers games. The beauty and complexity of the game lies in its special rule: captured checkers are not removed from the board, but placed under the capturing one, forming a tower. These towers move as one piece, the color and owner of which depend on the color of the top checker. When towers are captured, they lose the top checker, so they can change color and owner, which can turn the game around. Therefore, victory is possible if only one player can block or capture all of the opponent's pieces.",
        play: 'Enjoy awsome game',
        puzzles: "Resolve puzzle",
        homeQuestion: 'Have you ever played this game before?',
        invite: 'You can &nbsp;<button type="button" class="sign-btn">Sign In</button>&nbsp; to get full access or continue as &nbsp;<button type="button" class="guest-btn">Guest</button>&nbsp; with confines', 
        signin: 'Full Access',
        or: 'or',
        guest: "Guest Access",
        chatInvAuth: "Sign in to get access to chat",
        nicknameOptions: 'You can change your nickname any time on settings page',
        playGame: "Play game",
        stepOneTitle: 'Please help us maximize your pleasure',
        confirm: 'Confirm',
        step0che: 'Have you ever played any checkers?',
        step0int: 'Have you ever played international?',
        step0extra: 'How do you rate your rating?',
        step0rus: 'Have you ever played russian?',
        step0tow: 'Have you ever played checkers-towers?',
        step1che: 'Do you know rules of any checkers game?',
        step1int: 'Do you know rules of the international?',
        step1rus: 'Do you know rules of the russian?',
        step1tow: 'Have you understood rules of the checkers-towers?',
        stepTwoTitle: 'A few more questions',
        stepLastTitle: "Let's customize the board color and other options.",
        stepPuzzlesTitle: "At last some puzzles",
        stepLastConfirm: "Click this link to go to the settings page",
        puzzleCongrats: ['Awesome', 'Great', 'Fantastic', 'Excellent'],
        puzzleSorry: 'Opps. Wrong move',
        gameOptions:  'Game options',
        backToPuzzMenu: 'Back to menu',
        rematch: "Rematch",
        skip: "Skip",
        oops: " Oops. Wrong move",
        rulesKnown1: "barely",
        rulesKnown2: "solidly",
        rulesKnown3: "deeply",
        signToGetPuzzles: 'Sign In to get more puzzles'
    },
    ru: {
        homeTitle: 'Home',
        gameTitle: 'Game',
        shortMainTitle: 'Шашки',
        mainTitle: 'Столбовые Шашки',
        newGame: 'Новая игра',
        submitLog: 'Войти',
        submitReg: 'Зарегистрироваться',
        logTitle: 'Для входа в',
        regTitle: 'Для регистрации в',
        logMess: 'Пожалуйста введите свой ник или email и пороль',
        regMess: 'Пожалуйста заполните регистрационную форму',
        modeButtonLog: 'Еще не зарегистрированы?',
        modeButtonReg: 'Войти в аккаунт',
        labelPassword: 'Пароль',
        labelNickname: 'Игровой ник или email',
        submitButton: 'Отправить',
        about_title: "Добро пожаловать на Checkers Towers!",
        about_start: 'Столбовые шашки — одна из самых сложных и красивых игр в шашки. Ее красота и сложность заключается в особом правиле: побитые шашки не снимаются с доски, а ставятся под бьющую, образуя башню. Эти башни ходят как одна фигура, цвет и владелец которой зависит от цвета верхней шашки. Если башня попадет под бой она теряет верхнюю шашку, поэтому может сменить цвет и хозяина, что может перевернуть ход игры. Поэтому победа возможна, если только один из игроков сможет заблокировать или захватить все шашки противника.',
        play: 'Играй и получай удовольствие от восхитительной игры',
        puzzles: "Решить задачку",
        homeQuestion: 'Have you ever played this game before?',
        invite: 'Вы можете &nbsp;<button type="button" class="sign-btn">Войти</button>&nbsp; чтобы получить полный доступ или продолжить как &nbsp;<button type="button" class="guest-btn">Гость</button>&nbsp; с ограничениями', 
        signin: 'Полный доступ',
        or: 'or',
        guest: "Доступ гостя",
        chatInvAuth: "Войдите чтобы получить доступ к чату",
        nicknameOptions: 'Вы можете изменть свое имя в любой момент в настрйках',
        playGame: "Играть в игру",
        stepOneTitle: 'Пожалуйста помогите нам доставить вам максимкальное удовольствие',
        confirm: 'Подтвердить',
        step0che: 'Играли ли вы когда-нибудь в какие-либо шашки?',
        step0int: 'Играли ли вы когда-нибудь в международные шашки?',
        step0extra: 'Как вы оцениваете ваш рейтинг?',
        step0rus: 'Играли ли вы когда-нибудь в русские шашки?',
        step0tow: 'Играли ли вы когда-нибудь в столбовые шашки?',
        step1che: 'Знакомы ли вы с правилами какой-либо шашечной игры?',
        step1int: 'Знакомы ли вы с правилами международных шашек?',
        step1rus: 'Знакомы ли вы с правилами русских шашек?',
        step1tow: 'Понимаете ли вы правила столовых шашек?',
        stepTwoTitle: 'Еще несколкьо вопросов',
        stepLastTitle: "Давайте настроим цвет доски, шашек и другие опции игры",
        stepPuzzlesTitle: "Наконец несколко пазлов",
        stepLastConfirm: "Нажмите на ссылку, чтобы перейти на страницу настроек",
        puzzleCongrats: ['Замечательно', 'Превосходно', 'Великолепно'],
        puzzleSorry: 'Упс. Неверный ход',
        gameOptions:  'Настройки игры',
        backToPuzzMenu: 'Назад к меню',
        rematch: "Rematch",
        skip: "Skip",
        oops: " Упс. Неверный ход",
        rulesKnown1: "едва",
        rulesKnown2: "неплохо",
        rulesKnown3: "глубоко",
        signToGetPuzzles: 'Войдите, чтобы получть новые задачи'
    },
}
