import { useEffect } from 'react'
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux'

import git from '../../assets/images/icons8-github.svg'

// import { IRootState } from '../store/rootState&Reducer'
import {selectAppType, setAppType, setWindowSize} from '../../store/topStateSlice'
import WebSocketClient from '../../Web-sockets/Ws'
import { AppType, IRootState } from '../../models/models'
import { updateBoardState } from '../../store/boardSlice'
import { close } from '../../store/userSlice'
import { calcPiecePosition, updateCellsMap } from '../../local-engine/board-helper-fn'
import { ConnectionIndicator } from '../ConnectionIndicator/ConnectionIndicator'
import { copyObj, isDev } from '../../local-engine/gameplay-helper-fn'

import './footer.scss'
import { updatePosition } from '../../store/gameSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectIsGame } from '../../store/rootState&Reducer'
// import { updateDemoState } from '../../store/demoSlice'

const mapState = (state: IRootState) => ({
    board: state.board,
    position: state.game.position,
    token: state.user.token,
    windowSize: state.topState.windowSize,
    mode: state.user.viewMode,
    theme: state.user.theme,
})

const mapDispatch = {setWindowSize, updateBoardState, updatePosition, close}

const connector = connect(mapState, mapDispatch)

const Footer  = (props: ConnectedProps<typeof connector>) => {
    const location = useLocation()
    const navi = useNavigate()
    const dispatch = useDispatch()
    const type = useSelector(selectAppType)
    const isGame = useSelector(selectIsGame)
    useEffect(() => {
        isDev() && console.warn('footer did mount')
        const handleResize = () => {
            const {innerHeight: height , innerWidth: width} = window
            props.setWindowSize({width, height})
        }
        window.addEventListener('resize', handleResize)
        const onClose = (e: any) => {
            e.preventDefault();
            const token = props.token
            if (token) props.close()
        }
        window.addEventListener("beforeunload", onClose)
        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("beforeunload", onClose)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const {
            windowSize,
            board: {boardSize: bs, cellSize},
            updateBoardState,
            updatePosition,
            position: pos,
        } = props
        const {width, height} = windowSize
        const avWidth = width > 900
            ? Math.min(width < 1100 ? width - 250 - 10*bs : width - 500 - 10*bs, 600 , height * .8)
            : Math.min(width * .86, 600, height * .8)
        
        if (cellSize * bs < avWidth || bs * cellSize > avWidth + 3*bs) {
            const cellSize = Math.floor(avWidth/bs/10)*10
            // console.log(width, height, avWidth, cellSize)
            const position = copyObj(pos)
            const cellsMap = updateCellsMap({...props.board, cellSize})
            for (const key in pos) {
                position[key].DOM = calcPiecePosition(key, cellsMap, cellSize)
            }
            updateBoardState({cellSize, cellsMap})
            updatePosition(position)

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.windowSize])

    useEffect(() => {
        const page = location.pathname.split('/')[1]
        if (page !== type) {
            if (type !== AppType.game 
                && isGame 
                && (page === AppType.puzzles || page === AppType.analysis)) {
                return navi(-1)
            }
            const specialPage = (page === AppType.game && AppType.game)
                || (page === AppType.puzzles && AppType.puzzles)
                || (page === AppType.analysis && AppType.analysis)
            if (specialPage) {
                dispatch(setAppType(specialPage))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
   
    return (
        <footer id='footer' className={'noselect mode-' + props.mode + ' theme-' + props.theme} >
            <ConnectionIndicator />
            <a href="https://github.com/evgmsk" target="blanc" referrerPolicy="no-referrer">
                designed by evgmsk&nbsp;&nbsp;
                <img height="10px" width="10px" src={git} alt="github"/>
            </a>
            <WebSocketClient />
        </footer>
    )
}

export default (connector(Footer))
