import {
    PieceColor as PC,
    IPieces,
    Branch,
    DeepValue,
    IBoardPieces,
    Move,
    GameVariant,
} from '../models/models'
import { createDeepValue, getKingsNumber, isDev, oppositeColor, updateDV } from './gameplay-helper-fn'
import Evaluator, { evaluator as posEv } from './evaluator'
import { createStartPosition, getDepth } from './board-helper-fn'
import { 
    DefaultDepth, EvValLim, 
    ExtraTree, MainTree, EngineMovesLimit,
    MovesTailLim, PlayerMovesLimit,
    ValueGap
} from '../constants/gameConstants'
import { copyObj } from './gameplay-helper-fn'


export interface IBestMoveLine {line: string[], value: number}

export const DefaultTreeProps = (): ITreeProps => copyObj({
    engineColor: PC.white,
    engineLevel: 1,
    game: true,
    position: createStartPosition(posEv.moveR.size, posEv.moveR.GV),
    turn: PC.white,
})

export interface IPositionTree {
    [key: string]: Branch //| { children: Children }
}

export type TreeType = typeof MainTree | typeof ExtraTree

export interface ITreeProps {
    engineColor: PC, 
    engineLevel: number
    position: IPieces,
    turn: PC
    game: boolean
    moveLinesCB?: any
    tree?: IPositionTree
    uneval?: boolean
    evaluator?: Evaluator
    evalDepth?: number,
    size?: number,
    GV?: GameVariant
}

export class PositionTree {
    [MainTree] = {} as IPositionTree
    [ExtraTree] = {} as IPositionTree
    moveLines = [] as IBestMoveLine[]
    engineColor: PC
    engineLevel: number
    currentPos: IPieces
    evalDepth: number
    evaluator: Evaluator
    startTime = Date.now()
    game: boolean
    seal = 0
    extraDepth = 1
    shareMoveLinesCB = () => {console.error('cb not setted')}
    constructor(props: ITreeProps) {
        this[MainTree] = props.tree || {}
        this.moveLines = []
        this[ExtraTree] = {}
        this.engineColor = props.engineColor
        this.engineLevel = props.engineLevel
        this.evalDepth = props.evalDepth || getDepth(props.engineLevel)
        this.evaluator = props.evaluator || posEv
        this.currentPos = props.position       
        this.createRootWithPosition(this.currentPos, props.turn, this.engineColor)
        this.game = props.game
        if (props.GV) {
            const {size, GV} = props
            this.evaluator.moveR.setProps({size, GV})
        }
        !props.uneval && this.evaluateToGivenDepth()
        if (!this.game) {
            if (!props.moveLinesCB) {
                console.error('cb not provided')
            } else {
                this.setShareMoveLinesCB(props.moveLinesCB)
            }
            this.setMoveLines()
            this.shareMoveLinesCB()
            this.evaluateToExtraDepth()
        } else {
            this.setMoveLines()
        }
    }

    setPropsAndCreateTree = (props: Partial<ITreeProps>) => {
        this.engineColor = props.engineColor || props.turn || this.engineColor
        this.engineLevel = props.engineLevel || this.engineLevel
        this.evalDepth = props.engineLevel 
            ? getDepth(props.engineLevel) 
            : (props.evalDepth || this.evalDepth)
        this.evaluator = props.evaluator || posEv
        this.currentPos = props.position || this.currentPos
        this.createRootWithPosition(this.currentPos, (props.turn || PC.white), this.engineColor)
        this.game = props.game !== undefined ? props.game : this.game
        this.setSeal()
        this.extraDepth = 1
        !props.uneval && this.evaluateToGivenDepth()
        if (props.GV) {
            const {size, GV} = props
            this.evaluator.moveR.setProps({size, GV})
        }
        if (!this.game) {
            if (!props.moveLinesCB) {
                console.error('cb not provided')
            } else {
                this.setShareMoveLinesCB(props.moveLinesCB)
            }
            console.log(this.moveLines, copyObj(this.currentPos), props.position)
            this.evaluateToExtraDepth()
        } else {
            this.setMoveLines()
        }
        isDev() && console.warn('new tree', props, this.game)
    }
    setSeal = () => {
        this.seal = (this.seal + 1) % 100
    }

    createRootWithPosition = (towers: IBoardPieces, turn: PC, engine: PC) => {
        if (!towers || !turn || !engine) {
            console.error(
                'invalid props to create tree with pisition', 
                towers, turn, engine
            )
            return
        }
        this.engineColor = engine
        const position = {} as IBoardPieces
        for (const key in towers) {
            const {DOM, ...tower} = towers[key]
            position[key] = tower
        }
        const branch = {
            position,
            move: 'root',
            deepValue: {depth: 0},
            children: {},
            turn
        } as Branch
        delete this.tree.root
        this.tree.root = this.evaluatePositionDepth0(branch)
        if (Object.keys(this.getRoot().children)) {
            this.evaluatePositionD1(this.getRoot())
        } else {
            this.getRoot().deepValue.depth = 1
        }
        return this.tree.root
    }

    setShareMoveLinesCB = (cb: any) => {
        this.shareMoveLinesCB = () => cb(this.shareMoveLines())
    }
    
    setEngineColor = (color: PC) => {
        this.engineColor = color
    }
       
    createExtraTree = (source = this.getRoot()) => {
       this.extraTree.root = copyObj(source)
    }
    
    updateRoot = (move: string, tree = MainTree) => {
        const newRoot = this[tree as TreeType].root.children[move]
        if (!newRoot) {
            console.error(`invalid ${tree} to make move`,
                newRoot,
                tree,
                move,
                this.getOrderedMoves(),
                this.getExtraRoot().deepValue,
                this.getRoot().deepValue            
            )
            return
        }
        if (tree === MainTree) {
            this.currentPos = this.evaluator.moveR.makeMoves([move], this.currentPos)
        }
        newRoot.move = 'root'
        delete this[tree as TreeType].root 
        this[tree as TreeType].root = newRoot
        if (!this.game) {
            this.engineColor = newRoot.turn
        }
        return true
    }

    updateTreeWithPosition = (data: Move & {turn: PC}) => {
        this.startTime = Date.now()
        this.evalDepth = 1
        const {move, position, turn} = data
        this.currentPos = position
        this.engineColor = turn
        const root = this.getRoot().children[move]
        if (root) {
            this.tree.root = root
        } else {
            this.createRootWithPosition(position, turn, turn)
        }
        this.evaluateToGivenDepth()
        this.evaluateToExtraDepth()
    }
    
    updateTreeAfterMove = (move: string, tree = MainTree, evaluate = true) => {
        this.updateRoot(move, tree)
        const root = this[tree as TreeType].root
        if (evaluate && Math.abs(root.deepValue.value) < EvValLim) {
            this.evaluateToGivenDepth(this.evalDepth, root)
            if (!this.game) {
                this.setMoveLines()
                this.shareMoveLinesCB()
            }
        }
        if (evaluate 
            && Math.abs(root.deepValue.value) < EvValLim 
            && root.deepValue.depth < this.evalDepth) {
            isDev() && console.warn(this[tree as TreeType].root.deepValue, move, tree, evaluate, root.turn)
        }
    }
    
    getRoot = () => {
        return this.tree.root
    }
    
    getExtraRoot = () => {
        return this.extraTree.root
    }
    
    evaluatePositionDepth0 = (branch: Branch) => {
        const {position, turn} = branch
        if (!position) { 
            console.error('invalid argument to evaluate branch', branch.move)
            return branch
        }
        delete branch.position
        const {moves, deepValue} = this.evaluator.getPositionData(position, turn)
        if (deepValue.value > EvValLim) console.error('invalid evaluation', deepValue)
        for (const move of moves!) {
            branch.children[move.move] = {
                position: move.position,
                move: move.move,
                turn: oppositeColor(turn),
                children: {},
                deepValue: {} as DeepValue,
            }
        }
        branch.deepValue = deepValue
        return branch
    }

    makeExtraSearchStep = () => {
        
    }
    
    evaluatePositionD1 = (branch: Branch) => {
        const {children, turn, deepValue: DV} = branch
        if (DV.depth !== 0) {
            console.error('invalid branch to eval to depth 1')
        }
        const deepValue = createDeepValue(DV.depth + 1, turn)
        for (const move in children) { 
            branch.children[move] = this.evaluatePositionDepth0(branch.children[move])
            updateDV(deepValue, turn, branch.children[move])
        }
        if ((Math.abs(deepValue.value) < EvValLim && !deepValue.move) 
            || Math.abs(deepValue.value) > EvValLim) {
            console.error('failed deepValue', 
                deepValue, 
                Object.keys(branch.children).map(b => branch.children[b].deepValue))
        }
        branch.deepValue = deepValue
    }
    
    evaluateBranchNextDepth = (branch = this.getRoot()) => {
        if (!Object.keys(branch.children).length) {
            branch.deepValue.depth +=1
            branch.deepValue.value = branch.turn === PC.white 
                ? -EvValLim
                : EvValLim
                return
        }
        if (branch.deepValue.depth === 0) {
            return this.evaluatePositionD1(branch)
        } 
        const {turn, deepValue: DV} = branch
        const deepValue = createDeepValue(DV.depth + 1, turn)
        const orderedMoves = this.getOrderedMoves(branch)
        const moves = branch.deepValue.depth >= DefaultDepth 
            ? this.getCuttedMoves(orderedMoves)
            : orderedMoves
        for (const child of moves) {
           this.evaluateBranchNextDepth(branch.children[child])
           updateDV(deepValue, turn, branch.children[child])
        }
        if ((Math.abs(deepValue.value) < EvValLim && !deepValue.move) 
            || Math.abs(deepValue.value) > EvValLim
            || moves.length < Math.min(orderedMoves.length, EngineMovesLimit)
        ) {
            const ch = Object.keys(branch.children)
            console.error(
                'failed deepValue', 
                deepValue,
                branch.move,
                branch.turn,
                branch.deepValue,
                this.getRoot().deepValue, 
                this.getOrderedMoves().map(c => [this.getRoot().children[c].deepValue, c]), 
                ch.map(b => branch.children[b].deepValue),
                moves.length < Math.min(orderedMoves.length, EngineMovesLimit)
            )
        }
        branch.deepValue = deepValue
    }
    
    evaluateToGivenDepth = (depth = this.evalDepth, branch = this.getRoot()) => {
        while(branch.deepValue.depth < depth && Math.abs(branch.deepValue.value) < EvValLim) {
            this.evaluateBranchNextDepth(branch)
        }
        // console.log(this.game)
        if (!this.game) {
            this.setMoveLines()
            this.shareMoveLinesCB()
            // console.log(this.moveLines, copyObj(this.moveLines))
        }
    }

    evaluateToExtraDepth = (branch = this.getRoot()) => {
        if (Date.now() - this.startTime > 300 || Math.abs(branch.deepValue.value) === 30) return
        this.evaluateBranchNextDepth(branch)
        this.setMoveLines()
        this.shareMoveLinesCB()
        console.log('extra', this.moveLines, copyObj(this.tree))
        this.extraDepth += 1
        this.evaluateToExtraDepth()
    }
    
    getChange = (tree = ExtraTree) => {
       const [main, extra] = [
           this.getRoot().deepValue.value, 
           this[tree as TreeType].root.deepValue.value
       ]
       return this.engineColor === PC.white 
           ? parseFloat((extra - main).toFixed(3))
           : parseFloat((main - extra).toFixed(3))
    }
    
    makeMoves = (moves: string[], tree: TreeType = MainTree, evaluate = DefaultDepth) => {
        !this[tree].root && this.createExtraTree()
        let root = this[tree].root
        if (!root || !(moves[0] in root.children)) {
            console.error(
                'invalid moves to update Tree', 
                moves, 
                this.getOrderedMoves(), 
                tree, 
                this[tree]
            )
            return
        }
        for (const move of moves) {
            const updated = this.updateRoot(move, tree)
            if (!updated) break
        }
        if (!evaluate) return
        root = this[tree as TreeType].root
        this.evaluateToGivenDepth(evaluate, root)
    }

    checkTree = () => {
        const mL = this.getMovesLine()
        const pos = this.evaluator.moveR.makeMoves(mL, this.currentPos)
        const turn = mL.length % 2 ? oppositeColor(this.getRoot().turn) : this.getRoot().turn
        const value = this.evaluator.getPositionData(pos, turn).deepValue.value
        // deb && console.log(value, this.getRoot().deepValue.value, mL, this.engineLevel)
        return value !== this.getRoot().deepValue.value 
            ? [value, this.getRoot().deepValue.value, mL]
            : true
    }
    
    getOrderedMoves = (branch = this.getRoot()) => {
        const children = branch.children
        return Object.keys(children).sort((a, b) => {
            return branch.turn === PC.white
                ? children[b].deepValue.value - children[a].deepValue.value
                : children[a].deepValue.value - children[b].deepValue.value
        })
    }
 
    
    updateMoveLine = (first: string, last: string | string[], val: number, deb = false) => {
        const moveLines = copyObj(this.moveLines)
        for (let i = 0; i < moveLines.length; i++) {
            const line = moveLines[i].line
            if (line[0] === first) {
                moveLines[i].line = line.concat(last)
                moveLines[i].value = val
                break
            }
        }
        this.moveLines = moveLines
        if (!this.game && Array.isArray(last)) {            // console.warn('share', first, last, this.moveLines)
            this.shareMoveLinesCB()
        }
    }

    setMoveLines = (moves: string[] = this.getOrderedMoves()) => {
        const moveLines = this.getMoveLines(moves)
        this.moveLines = moveLines.map(line => ({
            value: this.getRoot().children[line[0]].deepValue.value,
            line
        }))
    }

    getCuttedMoves = (moves = this.getOrderedMoves()) => {
        // const lim = Math.max(EngineMovesLimit, Math.floor(moves.length / 2) )
        return moves.length > EngineMovesLimit 
            ? moves.slice(0, EngineMovesLimit - MovesTailLim).concat(moves.slice(-MovesTailLim)) 
            : moves
    }

    getPlCuttedMoves = (moves = this.getOrderedMoves(this.getExtraRoot())) => {
        return moves.length > PlayerMovesLimit 
            ? moves.slice(0, PlayerMovesLimit - MovesTailLim).concat(moves.slice(-MovesTailLim)) 
            : moves
    }

    setShortMoveLines = (moves: string[] = this.getCuttedMoves()) => {
        this.moveLines = moves.map(m => ({
            value: this.getRoot().children[m].deepValue.value,
            line: [m]
        }))
    }

    getPlayerBest = (): {value: number, move: string} => {
        const st = Date.now()
        const moves = this.getPlCuttedMoves()
        const moveValues = moves.map(m => ({
            move: m, value: this.getExtraRoot().children[m].deepValue.value
        }))
        for (const move of moves) {
            const playerTree = copyObj(this.getExtraRoot().children[move])
            this.evaluateToGivenDepth(this.evalDepth, playerTree)
            for (const line of moveValues) {
                if (line.move[0] === move) {
                    line.value = playerTree.deepValue.value
                    break
                }
            }
        }
        const move = moveValues.sort((a, b) => this.engineColor === PC.white 
            ? a.value - b.value 
            : b.value - a.value)[0]
        isDev() && console.log(Date.now() - st, moveValues, this.engineColor, move)
        return move
    }

    getBranch = (moves: string[], root = this.getRoot()) => {
        let branch = root
        for (const move of moves) {
            branch = branch.children[move]
            if (!branch) {
                console.error('invalid branch or moves', moves, Object.keys(root.children))
                return root
            }
        }
        return copyObj(branch)
    }

    
    resolveKingsEnding = (
        moves: string[], kings: number, pieces: number, depth = this.engineLevel - 4
    ) => {
        //#TODO kings endshpile

        this.setMoveLines()
    }

    getPassedTime = () => {
        return Date.now() - this.startTime
    }
    
    resolvePlayerMove = (data: {turn: PC, position?: IPieces, move: string}) => {     
        this.startTime = Date.now()
        this.setSeal()
        const {turn, position, move} = data
        const nextRoot = this.getRoot().children[move]
        if (nextRoot ) {
            this.updateTreeAfterMove(move)
        } else {
            try {
                isDev() && console.log('old tree', copyObj(this))
                this.setPropsAndCreateTree({turn: oppositeColor(turn), position})
                isDev() && console.warn('new tree', copyObj(this))
            } catch(e: any) {
                console.error('impossible to resolve move', e.message, data)
                return ''
            }
        }
        return this.getNextMove()          
    } 

    getRandomFromBests = (gap = ValueGap) => {
        const engineWhite = this.engineColor === PC.white
        const bestVal = this.moveLines.reduce((res, l) => {
            if ((!engineWhite && l.value < res)
                || (engineWhite && l.value > res)) {
                res = l.value
            }
            return res
        }, engineWhite ? -Infinity : Infinity)
        
        const movesWithSameVal = this.moveLines.filter(l => engineWhite 
            ? l.value >= bestVal - gap
            : l.value <= bestVal + gap
        )
        return movesWithSameVal[Math.floor(Math.random() * movesWithSameVal.length)] 
    }
    
    getNextMove = (moves = this.getCuttedMoves()) => {
        if (moves.length < 2) {
            this.setMoveLines(moves)
            isDev() && console.log('resolve: ', moves[0], copyObj(this.tree.root), copyObj(this.moveLines))
            return moves[0]
        }
        this.startTime = Date.now()
        if (this.engineLevel < 10) {
            this.setMoveLines(moves)
            isDev() && console.log('resolve random of: ', copyObj(this.tree.root), copyObj(this.moveLines))
            return this.getRandomFromBests(!this.engineLevel ? Infinity: ValueGap).line[0]
        }
        
        const [kings, pieces] = getKingsNumber(this.currentPos)
        if (kings > 2) { this.resolveKingsEnding(moves, kings, pieces) }
        // else {
        //     this.setShortMoveLines(moves)
        //     // this.makeExtraSearch(moves) 
        // }
        // isDev() && console.log(this.getPassedTime(), copyObj(this.tree.root), copyObj(this.moveLines))
        // return this.getRandomFromBests().line[0]
    }
    
    getMoveLines = (moves = this.getCuttedMoves(), length = Infinity, branch = this.getRoot()) => {
        const moveLines = [] as string[][]
        for (const move of moves) {
            const childBranch = branch.children[move]
            if (!childBranch) {
                console.error('invalid props to get move lines')
                return moveLines
            }
            moveLines.push([move].concat(this.getMovesLine(childBranch, length)))
        }
        return moveLines
    }
    
    getMovesLine = (
        branch = this.getRoot(),
        length = Infinity,
    ): string[] => {
        const line = [] as string[]
        let dV = branch.deepValue
        let nextBranch = branch.children[dV.move]
        //    line.push(dV.move)
        while (dV.move && line.length < length && nextBranch) {
            line.push(dV.move)
            dV = nextBranch.deepValue
            nextBranch = nextBranch.children[dV.move]
        }
        return line
    }

    shareMoveLines = () => {
        return this.moveLines.slice(0, 3)
    }
}

const posTree = new PositionTree(DefaultTreeProps())

export default posTree
   
    // diggingMovesLine = (
    //     mLine: string[],
    //     vDepth = 1,
    //     tLimit = TimeLimit, 
    //     bLimit = BreakLimit, 
    // ) => {
    //     return 
    // }
    
    // diggingMoveLines = (lines: string[][], depth = 1, tLimit = TimeLimit, bLimit = BreakLimit) => {
    //     const res = []
    //     if (lines[0].length > 1) {
    //         isDev() && console.warn('lines has extra length')
    //     }
    //     const maxIndex = lines.length - 1
    //     for (let i = 0; i <= maxIndex / 2; i++) {
    //         let moveRes = this.diggingMoveLine(lines[i][0], depth, this.getRoot(), tLimit, bLimit)
    //         res.push(moveRes)
    //         if (i !== maxIndex - i) {
    //             moveRes = this.diggingMoveLine(
    //                 lines[maxIndex - i][0], depth, this.getRoot(), tLimit, bLimit
    //             )
    //             res.push(moveRes)
    //         }
    //         if (this.game && (moveRes.change > 2 * BreakLimit || this.getPassedTime() > TimeLimit)) {
    //             isDev() && console.warn('starnge chages or time passed', this.moveLines, this.getPassedTime())
    //             break
    //         }
    //     }
    //     return res
    // }

    
    // diggingMoveLine = (
    //     move = this.getRoot().deepValue.move,
    //     depth = 1,
    //     source = this.getRoot(),
    //     tLimit = TimeLimit, 
    //     bLimit = BreakLimit,
    // ) => {
    //     if (!move || !source.children[move]) {
    //         console.error('invalid move for check', move, this.getOrderedMoves())
    //         return {change: -EvValLim}
    //     }
    //     this.createExtraTree(source.children[move])
    //     this.evaluateToGivenDepth(this.evalDepth, this.getExtraRoot())
    //     let nextDeepValue
    //     for (let i = 0; i < depth; i++) {
    //         nextDeepValue = this.getExtraRoot().turn === this.engineColor
    //             ? this.getExtraRoot().deepValue
    //             : this.getPlayerBest()
    //         this.updateMoveLine(move, nextDeepValue.move, nextDeepValue.value)
    //         const change = this.getChange()
    //         if (change < -2 * bLimit
    //             || (this.game && change > bLimit) 
    //             || (tLimit && this.getPassedTime() > tLimit) 
    //         ) {
    //             console.log('break', 
    //                 change, 
    //                 change < -2 * bLimit, 
    //                 this.game && change > bLimit, 
    //                 tLimit && this.getPassedTime() > tLimit, 
    //                 this.getPassedTime(),
    //                 i,
    //                 move,
    //                 this.moveLines.filter(l => l.line[0] === move)[0],
    //                 source.turn
    //             )
    //             break
    //         }
    //         if (i < depth - 1) {
    //             this.updateTreeAfterMove(move, ExtraTree)
    //         }
    //     }
        
    //     const restMoves = this.getMovesLine(this.getExtraRoot()).slice(1)
    //     this.updateMoveLine(move, restMoves, this.getExtraRoot().deepValue.value)
    //     return { move, change: this.getChange() }
    // }
        
    // makeExtraSearch = (moves = this.getCuttedMoves(), depth = 1) => {
         
    //     this.diggingMoveLines(moves.map(m => [m]), depth)
    //     !this.game && console.log('analysis', this.moveLines, depth)
    // }
