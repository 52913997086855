import { 
    BaseCellSize, SideLegendValues, TopLegendValues, BaseBoardSize, 
    PieceCellRatio
} from "../constants/gameConstants"
import { 
    CellsMap,
    EngineBoard,
    IGameBoard,
    INeighborCells,
    IPiecePosition,
    PieceColor,
    IBoardPieces,
    IPieces,
    IBoardTower,
    PieceType,
    ITower,
} from "../models/models"

import {
    getBlackTowersCells,
    getWhiteTowersCells,
} from '../constants/gameConstants'

import { BoardTower, Tower } from './gameplay-helper-fn'

export const getPlayerColor = (pc: PieceColor | 'random') => {
    if (pc === 'random') {
        return Math.random() < 0.5 ? PieceColor.white : PieceColor.black
    }
    return pc
}

export function getDepth(level: number) {
    return Math.max(Math.floor(level / 5) * 5, level % 5 + 1)
}

export const createStartPosition = (boardSize = BaseBoardSize, GV = 'towers'): IPieces => {
    const towers = {} as IPieces 
    getBlackTowersCells(boardSize).forEach((key: string) => {
        towers[key] = GV === 'towers'
            ? Tower({
                color: PieceColor.black,
            })
            : {color: PieceColor.black, type: PieceType.man} as ITower
    })
    getWhiteTowersCells(boardSize).forEach((key: string) => {
        towers[key] = GV === 'towers'
        ? Tower({
            color: PieceColor.white,
        })
        : {color: PieceColor.white, type: PieceType.man} as ITower
    })
    return towers
}

export function removeOutboardTowers(towers: IBoardPieces) {
    const unused = {} as IBoardPieces
    const position = {} as IBoardPieces
    for (const key in towers) {
        if (key.length > 3) {
            unused[key] = towers[key]
        } else {
            position[key] = towers[key]
        }
    }
    return {unused, position}
}


export const getNumbersOfUnusedTowers = (towers: IPieces) => {
    return Object.keys(towers).reduce(
        (acc, key) => {
            acc.white += key.includes('oW') ? 1 : 0
            acc.black += key.includes('oB') ? 1 : 0
            return acc
        },
        { black: 0, white: 0 }
    )
}

export function createOutBoardTowers(
    towers = {} as IBoardPieces,
    bs = 8
): IBoardPieces {
    const { usedBlack, usedWhite } = Object.keys(towers).reduce(
        (acc, key) => {
            const { white = 0, black = 0 } =
                towers[key] || ({} as IBoardTower)
            acc.usedWhite += white
            acc.usedBlack += black
            return acc
        },
        { usedBlack: 0, usedWhite: 0 }
    )
    const totalPieces = bs === 10 ? 20 : 12
    const [unusedBlack, unusedWhite] = [
        totalPieces - usedBlack,
        totalPieces - usedWhite,
    ]
    for (let i = 0; i < unusedBlack; i++) {
        const oBKey = `oB b${i}`
        towers[oBKey] = BoardTower({
            color: PieceColor.black,
            DOM: calcPositionOutboardBoxes(oBKey)
        })
    }
    for (let i = 0; i < unusedWhite; i++) {
        const oBKey = `oW w${i}`
        towers[oBKey] = BoardTower({
            color: PieceColor.white,
            DOM: calcPositionOutboardBoxes(oBKey)
        })
    }
    return towers
}

export function defineNeighborCells(
    i: number,
    j: number,
    size: number
): INeighborCells {
    const topLegend = TopLegendValues.slice(0, size)
    const sideLegend = SideLegendValues.slice(0, size)
    const neighbors: INeighborCells = {}
    if (i) {
        if (j < size - 1) {
            neighbors.leftUp = `${topLegend[i - 1]}${sideLegend[j + 1]}`
        }
        if (j) {
            neighbors.leftDown = `${topLegend[i - 1]}${sideLegend[j - 1]}`
        }
    }
    if (i < size - 1) {
        if (j < size - 1) {
            neighbors.rightUp = `${topLegend[i + 1]}${sideLegend[j + 1]}`
        }
        if (j) {
            neighbors.rightDown = `${topLegend[i + 1]}${sideLegend[j - 1]}`
        }
    }
    return neighbors
}

export const getDirection = (start: string, end: string) => {
    const [st0, st1] = start.split('')
    const [en0, en1] = end.split('')
    if (st1 > en1) {
        if (st0 > en0) {
            return 'leftDown'
        }
        if (st0 < en0) {
            return 'rightDown'
        }
    } else {
        if (st0 > en0) {
            return 'leftUp'
        }
        if (st0 < en0) {
            return 'rightUp'
        }
    }
    console.error('invalid props to detect diagonal')
    return ''
}

export const createEngineBoard = (size: number = BaseBoardSize): EngineBoard => {
    const GameBoard: EngineBoard = {}
    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            if ((i + j + 1) % 2) {
                const key = `${TopLegendValues[i]}${SideLegendValues[j]}`
                GameBoard[key] = {
                    boardKey: key,
                    neighbors: defineNeighborCells(i, j, size),
                }
            }
        }
    }
    return GameBoard
}

export function determineCellPosition(
    key: string,
    cellSize: number,
    reversed = false,
    boardSize = BaseBoardSize,
) {
    const topInd = reversed
        ? TopLegendValues.slice(0, boardSize).reverse()
        : TopLegendValues.slice(0, boardSize)
    const sideInd = reversed
        ? SideLegendValues.slice(0, boardSize)
        : SideLegendValues.slice(0, boardSize).reverse()
    const y = sideInd.indexOf(parseInt(key.slice(1))) * cellSize
    const x = topInd.indexOf(key[0]) * cellSize
    return { x, y }
}

export function createCellsMap(
    boardSize: number,
    cellSize = BaseCellSize,
    reversed = false,
    
) {
    const map = {} as CellsMap
    const boardWithoutTowers = createEngineBoard(boardSize)
    for (const key in boardWithoutTowers) {
        map[key] = determineCellPosition(key, cellSize, reversed, boardSize)
    }
    return map
}

export const updateCellsMap = (board: Partial<IGameBoard>) => {
    const {cellSize, cellsMap, boardSize: bs, reversedBoard: reversed} = board
    const newMap = {} as CellsMap
    for (const key in cellsMap) {
        newMap[key] = determineCellPosition(
            key,
            cellSize!,
            reversed!,
            bs,
        )
    }
    return newMap
}

export const calcPositionOutboardBoxes = (key: string) => {
    const {x: bX = 0, y: bY = 0, width: bWidth = 0} = 
        document.querySelector('.board__body')?.getBoundingClientRect() || {}
    const whiteBox = document.querySelector('.pieces-box.white-box')
    const blackBox = document.querySelector('.pieces-box.black-box')
    if (!whiteBox || !blackBox || !bWidth) {
        return { x: 0, y: 0 }
    }
    const { x, y, height, width } = key.includes('oW')
        ? blackBox.getBoundingClientRect()
        : whiteBox.getBoundingClientRect()
    const pieceSize = bWidth * PieceCellRatio / BaseBoardSize
    return { x: x - bX + width / 2 - pieceSize / 2, y: y - bY + height / 2 - pieceSize / 2 }
}

export const createDemoCellsMap = (cS: number, bs = BaseBoardSize) => {
    const cM = createCellsMap(bs, cS)
    return {cS, cM}
}

export const createDemoStartPosition = (cS: number, bs = BaseBoardSize) => {
    const {cM} = createDemoCellsMap(cS, bs)
    const stPos = createStartPosition(bs)
    const position = updatePiecesPosition(stPos, cM, cS)
    return {cS, cM, position}
}

export const updatePiecesPosition = (
    position: IBoardPieces, 
    map: CellsMap, 
    cellSize: number,
    excides = [] as string[]
): IBoardPieces => {
    const pieces = {} as IBoardPieces
    for (const key in position) {
        pieces[key] = {...position[key]}
        if (!excides.includes(key)) {
            pieces[key].DOM = calcPiecePosition(key, map, cellSize)
        }
        
    }
    return pieces
}

export const getFreeCells = (cellsMap: CellsMap, pos: IBoardPieces) => {
    const freeC = {} as CellsMap
    for (const key in cellsMap) {
        if (!pos[key]) {
            freeC[key] = cellsMap[key]
        }
    }
    return freeC
}

export const isValidPos = (pos: IBoardPieces) => {
    let white = false, black = false
    for (const key in pos) {
        if (key.length <= 3) {
            white = white || !!pos[key].white
            black = black || !!pos[key].black
            if (white && black) return true
        }
    }
    return white && black
}

export const calcPiecePosition = (
    key: string,
    cellsMap: CellsMap,
    cellSize: number,
): IPiecePosition => {
    const outboardTower = key.includes('oB') || key.includes('oW')
    if (outboardTower) {
        return calcPositionOutboardBoxes(key)
    }
    const { x, y } = cellsMap[key] || { x: 0, y: 0 }
    const width = .8 * cellSize
    return {
        x: Math.round(x - width / 2 + cellSize / 2),
        y: Math.round(y - width / 2 + cellSize / 2),
    }
}
