import { useDispatch, useSelector } from 'react-redux'
// import { selectTheme, selectMode } from '../../store/userSlice'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PuzzlesMenu } from './PuzzlesMenu'
import { useEffect, Suspense } from 'react'
import { AppType, IPuzzle } from '../../models/models'
import { selectIsGame, selectUserWithProps, selectWithProps } from '../../store/rootState&Reducer'
import { choosePuzzle, selectAppType, setAppType, startEngine } from '../../store/topStateSlice'
import Spinner from '../../UI-components/Spinners/Spinner'
import { Analysis, TopState } from '../../constants/gameConstants'

import './puzzles.scss'

export const Puzzles = () => {
    const { selectedPuzzle: puzzleId } = useSelector(selectWithProps(['selectedPuzzle'], TopState))
    const { resolvedPuzzles } = useSelector(selectUserWithProps(['resolvedPuzzles']))
    const {puzzles} = useSelector(selectWithProps(['puzzles'], TopState))
    // const puzzle = useSelector(selectPuzzle)
    const location = useLocation()
    const isGame = useSelector(selectIsGame)
    const {
        viewMode: mode, theme
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'exp']))
    
    const navi = useNavigate()
    const dispatch = useDispatch()
   
    useEffect(() => {
        if (isGame) {
            navi('../game')
            return
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        console.log(
            location.pathname, 
            !puzzleId,
            resolvedPuzzles, 
            location.pathname.split('/')[2], 
            puzzles.some((p: IPuzzle) => p._id === location.pathname.split('/')[2]),
            !resolvedPuzzles?.includes(location.pathname.split('/')[2])
        )
        if (location.pathname === '/puzzles' && puzzleId) {
            dispatch(choosePuzzle(''))
        } else if (location.pathname !== '/puzzles'
            && puzzles.some((p: IPuzzle) => p._id === location.pathname.split('/')[2]
            && !resolvedPuzzles?.includes(location.pathname.split('/')[2])
        )
        ) {
            dispatch(choosePuzzle(location.pathname.split('/')[2]))
            navi(`${puzzleId}`)
        }
        // if (type !== AppType.puzzles) {
        //     dispatch(setAppType(AppType.puzzles))
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
    useEffect(() => {
        if (puzzleId && location.pathname === '/puzzles') {
            navi(`${puzzleId}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzleId])
    // console.log(location, puzzle)
    return (
        <Suspense fallback={<Spinner />}>
            <div className={`page-wrapper noselect mode-${mode} theme-${theme}`}>
                {
                    puzzleId
                    ? <Outlet />
                    : <PuzzlesMenu />
                }
            </div>
            
        </Suspense>
    )
}

export default Puzzles


