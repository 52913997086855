import {useSelector} from 'react-redux'

import { GPClass } from '../../constants/gameConstants';
import { PieceColor, PieceType, IBoardTower, IRootState } from '../../models/models';
// import crownF from '../../assets/images/crown.svg'
// import { updatePiece } from '../../store/game/gameSlice';

import './game-piece.scss';
import { FaCrown } from 'react-icons/fa6';
import { 
	selectBoardWithProps, selectPiece, selectUserWithProps
} from '../../store/rootState&Reducer';

interface FaceProps {
	white: number, black: number, colorW: boolean, king: boolean, view: string
}

export const Piece: React.FC<FaceProps> = (props) => {
	const {white, black, colorW, king, view} = props
	// if (!towers) {
	// 	const className = `${colorW ? 'white-checker' : 'black-checker'}${king ? ' king' : ''}`
	// 	return <div className={className}>{king && <img className="king-mark" src={crownF} alt="K" />}</div>
	// }
	const WPClass = view === 'face' ? "white-piece" : 'white-checker'
	const BPClass = view === 'face' ? "black-piece" : 'black-checker'
	const BlackPieces = new Array(black).fill(0) 
	const WhitePieces = new Array(white).fill(1)
	const towerPiecesArray = colorW
		? WhitePieces.concat(BlackPieces) 
		: BlackPieces.concat(WhitePieces)
	const middle = Math.floor(towerPiecesArray.length / 2)
	const Numbers = colorW 
		? <p className="numbers-on-hover">
			<span className="white-top">{white}</span>
			<span className="black-down">{black}</span>
		</p>  
		: <p className="numbers-on-hover">
			<span className="black-top">{black}</span>
			<span className="white-down">{white}</span>
		</p>
	const Tower = towerPiecesArray.map((pn: number, i: number) => {
		const pos = Math.abs(i - middle)
		const BlackOrWhite = pn ? WPClass : BPClass
		const UpOrDown = i <= middle ? `up${pos}` : `down${pos}`
		const KingAndTop = king && !i ? ' king' : ''
		const pieceClass = `${BlackOrWhite} ${UpOrDown}${KingAndTop}`
		return (
			<div key={i} className={pieceClass}>
				{king && !i ? <FaCrown /> : null}
				{!i ? <div className='top-piece-circle'><span className="top-piece-circle_inner" /></div> : null}
			</div>
		)
	})
	return (
		<div className="tower-wrapper">
			{Tower}
			{towerPiecesArray.length > 1 ? Numbers: null}
		</div>
	)
}

// export const NumsPresentation:React.FC<{
// 	white: number, black: number, colorW: boolean, king: boolean
// }> = (props) => {
//   const {white, black, colorW, king} = props
//   const firstNum = colorW ? white : black
//   const secondNum = colorW ? black : white
//   const Class = `${GPClass}__quantity${king ? ' with-crown' : ''}`
//   return <div className={Class}>
//             <span>{firstNum}</span>&nbsp;/&nbsp;<span>{secondNum}</span>
//           </div>
// }

// const mapState = (state: IRootState, props: {posKey: string}) => ({
// 	isTowers: state.gameSettings.gameVariant === 'towers',
// 	gamePiece: state.game.position[props.posKey],
// 	view: state.board.towerView,
// 	pieceColors: state.user.pieceColors,
// 	cellSize: state.board.cellSize
// })

// const connector = connect(mapState, {})

// type PieceProps = ConnectedProps<typeof connector> & {posKey: string}

export interface ITowerProps {
	posKey: string,
	gamePiece: IBoardTower, 
	view: string, 
	pieceColors: string[],
	cellSize: number
}

export const Tower = (props: {posKey: string}) => {
    const {
		posKey,
	} = props
	const {cellSize} = useSelector(selectBoardWithProps(['cellSize', "towerView"]))
	const {
		pieceColors: pcs, towerView: view,
	} = useSelector(selectUserWithProps(['pieceColors', 'towerView']))
	const {
		DOM,
		color,
		type,
		white = 0,
		black = 0,
		mandatory: mt,
	} = useSelector(selectPiece(posKey))
	let {x, y} =  DOM || {}
	
	const className = `${GPClass} ${color} pcs-${pcs[0]}-${pcs[1]} cs-${cellSize}${mt? ' m-tower': ''}${view === 'face' ? ' towers' : ''}`
	const style = {top: `${y}px`, left: `${x}px`} //  {transform: `translate(${x}px, ${y}px)`}//
	
	const colorW = color === PieceColor.white 
	// const towerView = (white! + black! > 1) && view !== 'face'
	const _props={
		white, 
		black, 
		colorW, 
		king: type === PieceType.king, 
		view,
	}
	return 	<div className={className} data-indexes={posKey} style={style}>
				<Piece {..._props}/>
			</div>  
}

export const TowerComponent = (props: ITowerProps) => {
    const {
		posKey,
		gamePiece, 
		view, 
		// isTowers,
		pieceColors: pcs,
		cellSize
	} = props
	const {
		DOM,
		color,
		type,
		white = 0,
		black = 0,
		mandatory: mt,
	} = gamePiece || {}
	let {x, y} =  DOM || {}
	const className = `${GPClass} ${color} pcs-${pcs[0]}-${pcs[1]} cs-${cellSize}${mt? ' m-tower': ''}${view === 'face' ? ' towers' : ''}`
	const style = {top: `${y}px`, left: `${x}px`} //  {transform: `translate(${x}px, ${y}px)`}//
	
	const colorW = color === PieceColor.white 
	// const towerView = (white! + black! > 1) && view !== 'face'
	const _props={
		white, 
		black, 
		colorW, 
		king: type === PieceType.king, 
		view,
	}
	return 	<div className={className} data-indexes={posKey} style={style}>
				<Piece {..._props}/>
			</div>  
}

export default TowerComponent


export const BlackTower = () => {
	const cellSize = useSelector((state: IRootState) => state.board.cellSize)
	const towerView = useSelector((state: IRootState) => state.user.towerView)
	const gamePiece = {
		color: PieceColor.black,
		white: 0,
		black: 1,
		type: PieceType.man,
		DOM: {x: 0, y: 0}
	}
	return <TowerComponent
				gamePiece={gamePiece} 
				view={towerView} 
				pieceColors={["black", "white"]} 
				cellSize={Math.max(cellSize -10, 25)} 
				posKey={''}
			/>
}

export const WhiteTower = () => {
	const cellSize = useSelector((state: IRootState) => state.board.cellSize)
	const towerView = useSelector((state: IRootState) => state.user.towerView)
	const gamePiece = {
		color: PieceColor.black,
		white: 1,
		black: 0,
		type: PieceType.man,
		DOM: {x: 0, y: 0}
	}
	return <TowerComponent 
				gamePiece={gamePiece} 
				view={towerView} 
				pieceColors={["black", "white"]} 
				cellSize={Math.max(cellSize -10, 25)} 
				posKey={''}
			/>
}