import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useCloseEffect = (
    className: string, 
    setDropdown: Function, 
    device: boolean
) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [listeners, setListeners] = useState(false)
    
    useEffect(() => {
        const click = (ev: any) => {
            const target = ev.target
            
            const href = target.getAttribute('href')
            console.log('click', ev, href, location.pathname)
            if (ev.type === 'touchend' && href && href !== location.pathname) {
                navigate(href)
            }
            if (!target.classList.contains(className)) {
                setDropdown(false)
                setListeners(false)
            }
        }
        if (device && listeners) {
            window.addEventListener('click', click)
            window.addEventListener('touchend', click)
        } else {
            window.removeEventListener('click', click)
            window.removeEventListener('touchend', click)
        }
        return () => { 
            window.removeEventListener('click', click)
            window.removeEventListener('touchend', click) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device, listeners])
    return setListeners
}

export default useCloseEffect
