import React from 'react'
import { useSelector } from 'react-redux';

import { BoardNotation, Directions, ICell, IGameBoard } from '../../models/models'
import { TopLegendValues, SideLegendValues, BoardCl, MPS, Board } from '../../constants/gameConstants'
import { 
    selectGameWithProps, selectLastMove, selectPuzzle, selectUserWithProps, selectWithProps 
} from '../../store/rootState&Reducer';
import { dirToTarget } from '../../local-engine/gameplay-helper-fn';

import './board.scss';
import { BsBoxArrowInDownLeft, BsBoxArrowInDownRight, BsBoxArrowInUpLeft, BsBoxArrowInUpRight } from 'react-icons/bs';

const aStyle = (dir: Directions, size: number, rev = false) => ({
    width: `${size*.9}px`,
    height: `${size*.9}px`,
    top: ((!rev && dir.includes("Down")) || (rev && !dir.includes("Down"))) ? 0 : 'unset',
    left:((!rev && dir.includes("right")) || (rev && !dir.includes("right"))) ? 0 : 'unset',
    right: ((!rev && dir.includes("left")) || (rev && !dir.includes("left"))) ? 0 : 'unset',
    bottom: ((!rev && dir.includes("Up")) || (rev && !dir.includes("Up"))) ? 0 : 'unset'
})

const getTArrow = (data: any[], size: number, rev = false) => {
    const dir = data[0] as Directions
    const ind = data[1] as number
    switch (true) {
        case dir === Directions.ru: {
            const style = aStyle(dir, size, rev)
            return <span className={`arrow-in t-${dir[0]} d-${ind}) }`} style={style}>
                        { rev ? <BsBoxArrowInDownLeft /> : <BsBoxArrowInUpRight /> }
                    </span>
        }
        case  dir === Directions.lu: {
            const style = aStyle(dir, size, rev)
            return <span className={`arrow-in t-${dir} d-${ind}`} style={style}>
                        { rev ?  <BsBoxArrowInDownRight /> : <BsBoxArrowInUpLeft /> }
                    </span>
        }
        case  dir === Directions.rd: {
            const style = aStyle(dir, size, rev)
            return <span className={`arrow-in t-${dir} d-${ind}`} style={style}>
                        { rev ? <BsBoxArrowInUpLeft /> : <BsBoxArrowInDownRight /> }
                    </span>
        }
        case  dir === Directions.ld: {
            const style = aStyle(dir, size, rev)
            return <span className={`arrow-in t-${dir} d-${ind}`} style={style}>
                        { rev ? <BsBoxArrowInUpRight /> : <BsBoxArrowInDownLeft /> }
                    </span>
        }
        default: return null
    }
}

export const Cell: React.FC<ICell> = props  => {
    return props.className.includes('dark')
            ? <div
                className={props.className}
                data-indexes={props.indexes}
            >
                {props.children}
            </div>
            : <div className={props.className}>
                {props.children}
            </div>
}

export const BoardComponent = React.memo((props: {bs?: number, bw?: number, demo?: boolean}) => {
    const boardProps = ['boardNotation', 'reversedBoard','towerTouched','cellSize', 'boardSize']
    const {
            reversedBoard,
            towerTouched,
            cellSize,
            boardSize: _bs
        } = useSelector(selectWithProps(boardProps, Board)) as IGameBoard
    // const helpRequest = useSelector((state: IRootState) => state.game.helpRequest)
    const {
        exp: {onboardingPassed}, moveHighlight, boardNotation
    } = useSelector(selectUserWithProps(['exp', 'arrowHighlight', 'moveHighlight', 'boardNotation']))
    const {nextMoves} = useSelector(selectGameWithProps(['nextMoves']))
    const puzzle = useSelector(selectPuzzle)
    const extraHighlights = (!onboardingPassed && puzzle && puzzle.level <= 3) 
    const boardSize = props.bs || _bs
    const lastMove = useSelector(selectLastMove)
    const size = props.bw || cellSize*boardSize
    const possibleMoves = towerTouched?.possibleMoves || {}
    const DefaultTL = TopLegendValues.slice(0, boardSize)
    const DefaultSL = SideLegendValues.slice(0, boardSize)
    const SL  = reversedBoard ? DefaultSL : DefaultSL.reverse()
    const TL = reversedBoard ? DefaultTL.reverse() : DefaultTL
    let k = reversedBoard ? 51 : 0
    const BoardElem= SL.map((v: number, i: number) => {
        return TL.map((h: string, j: number) => {
            if(reversedBoard) {
                k = (i + j) % 2 ? k - 1 : k
            } else {
                k = (i + j) % 2 ? k + 1 : k
            }
            const type = !((i + j) % 2) ? 'light' : 'dark'
            const index = `${h}${v}`
            const marked = possibleMoves[index] ? ' marked' : ''
            const moveIndex = lastMove.indexOf(index)
            const highlighted = moveHighlight && moveIndex >= 0 
                ? ' highlighted' 
                : ''
            
            const tDir = extraHighlights ? dirToTarget(nextMoves.map(m => m.move), index) : []
            // console.log(highlighted, extraHighlights, tDir, nextMoves.map(m => m.move), index)
            const className = `board__cell ${type}${marked}${highlighted}`
            return (
                <Cell
                    key={i + MPS + j}
                    indexes={index} 
                    className={className}
                >
                    {
                        boardNotation === BoardNotation.dr 
                        && ((i + j) % 2)
                        && (!j || i + 1 === SL.length || !i || j + 1 === TL.length)
                        ? <span className="board__label-value">{k}</span> 
                        : null
                    }
                    {
                        boardNotation === BoardNotation.ch && !j
                        ? <span className="board__label-value ver">{SL[i]}</span>
                        : null
                    }
                    {
                        boardNotation === BoardNotation.ch && i + 1 === SL.length
                        ? <span className="board__label-value hor">{TL[j]}</span> 
                        : null
                    }
                    {tDir[0] ? getTArrow(tDir, cellSize, reversedBoard) : null}
                </Cell>
            )
        })
    })
    const className = `${BoardCl} bs-${boardSize}`
    const style = { width: `${size}px`, height: `${size}px`}
    return (
         <div className={className} style={style}>
            {BoardElem}
        </div>
    )  
})
