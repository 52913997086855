import { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {TiHomeOutline} from 'react-icons/ti'
import {LuSettings} from 'react-icons/lu'
// import { LiaSearchPlusSolid } from "react-icons/lia";
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { TbPuzzle2 } from "react-icons/tb";
import {RoutePaths} from '../../constants/gameConstants'
import {FiMenu} from 'react-icons/fi'
import {LoginBar} from './LoginBar'
import {Logo} from '../Logo/LogoIcon'
import useCloseEffect from '../../hooks/useCloseEffect'
import useDevice from '../../hooks/useDevice'
import { selectUserWithProps } from '../../store/rootState&Reducer'
import { LanguageSwitcher } from '../CtrlBtns/CtrlBtn'

export const Navbar = () => {
    const {token, name, exp} = useSelector(selectUserWithProps(['token', 'name', 'exp']))   
    const [isAuth, setIsAuth] = useState(!!token)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const device = useDevice()
    const addListeners = useCloseEffect('navbar-device-menu-btn', setDropdownOpen, device)
    useEffect(() => {
        if (!device && !dropdownOpen) {
            setDropdownOpen(true)
        } else if (device && dropdownOpen) {
            setDropdownOpen(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device])
    useEffect(() => {
        setIsAuth(!!token)
    }, [token])
    
    const className = `${dropdownOpen ? 'device-menu' : 'hidden'}`
    return (
        <nav className={`nav-wrapper`}>
            {!exp.onboardingPassed ? <LanguageSwitcher /> : null}
            <button
                className={`navbar-device-menu-btn${!device ? ' hidden' : ''}`}
                type="button"
                onClick={() => {setDropdownOpen(!dropdownOpen); addListeners(true);}}
                aria-controls="navbarContent"
                aria-expanded={ dropdownOpen ? "true" : "false"}
                aria-label="Toggle navigation">
                <FiMenu />
            </button>
            <ul className={`${!device ? '' : className}`}
                id="navbarContent"
            >
                <li title="home">
                    <NavLink className="navlink" to={RoutePaths.home} >
                        <TiHomeOutline />
                    </NavLink>
                </li>
                <li title="puzzles">
                    <NavLink className="navlink" to={name && RoutePaths.puzzles} >
                        <TbPuzzle2 />
                    </NavLink>
                </li>
                <li title="game">
                        <NavLink className="navlink" to={name && RoutePaths.game} >
                            <Logo />
                        </NavLink>
                </li>
                {!isAuth && <li>
                    <NavLink className="navlink" to={name && RoutePaths.profile} >
                        <LuSettings />
                    </NavLink>
                </li>}
                {isAuth && ['analysis'].map(path => (
                        <li key={path} title={`${path}`}>
                            <NavLink  className="navlink" to={RoutePaths[path]}>
                                <PiMagnifyingGlassPlusBold />
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
            <LoginBar />
        </nav>
    )
}

export default Navbar
