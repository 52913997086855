import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import{ BsXCircle } from "react-icons/bs";
import {
    requestRevenge, createChallenge, setGameState, InitialGameState
} from '../../store/gameStateSlice'

import { AppType, GameResult, IPuzzle, ITopState } from '../../models/models'
import { EngineLevels, TopState } from '../../constants/gameConstants'
import { Ads } from '../Ads/Ads'
// import { MdCancel } from 'react-icons/md';
import { 
    selectGameOptionsWithProps, selectGameStateWithProps, selectUserWithProps, selectWithProps 
} from '../../store/rootState&Reducer'
import { I18n } from '../../assets/i18n'
// import { playMoves } from '../../store/gameSlice'
import { useLocation } from 'react-router-dom'

import './event-end-popup.scss'
import { choosePuzzle } from '../../store/topStateSlice'
import { LuX } from 'react-icons/lu'


export const EventEndPopup = () => {
    // const navi = useNavigate()
    const location = useLocation()
    const {
        result, playerColor
    } = useSelector(selectGameStateWithProps(['result', 'playerColor']))
    const {
        rivalType, rivalLevel, timing: {adds, gameTimeLimit}
    } = useSelector(selectGameOptionsWithProps(['rivalType', 'rivalLevel', 'timing']))
    const {puzzleResolved, selectedPuzzle} = useSelector(
        selectWithProps(['puzzleResolved', 'selectedPuzzle'], TopState)
    )  as ITopState
    const {name, language, exp: {onboardingPassed}} = useSelector(
        selectUserWithProps(['name', 'language', 'exp'])
    )
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const {type, puzzles} = useSelector(selectWithProps(['type', 'puzzles'], TopState))
    const vsEngine = rivalType === 'engine'
    const [show, setShow] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        const show = onboardingPassed 
            && !!((result && location.pathname.includes('/game/')) 
            || (puzzleResolved?.animated && location.pathname.includes('/puzzles/')))
            setShow(show)
    }, [result, puzzleResolved, location, onboardingPassed])
    // console.log('event end', selectedPuzzle, gameKey, (!result && !puzzleResolved) || !show)

    if ((!result && !puzzleResolved) || !show) {return null}
    
    const playerWin = result === GameResult[playerColor] || puzzleResolved?.resolved

    const handleClose = () => {
        setShow(false)
        // if (puzzleResolved && type === AppType.puzzles) {
        //     dispatch(playMoves())
        // }
    }
    
    const message = result 
        ? (result === GameResult.draw
            ? "Draw"
            : (result === GameResult.canceled
                ? 'Game canceled'
                : playerWin 
                    ? `${i18n.gameCongrats || 'Congratulations! you win'}` 
                    : `${i18n.gameLose || 'You lose'}`
            )) 
        : (playerWin 
            ? `${i18n.puzzleCongrats[Math.floor(Math.random()*4)]} ${name}!`
            : i18n.puzzleSorry
        )
    
    const handleRematch = () => {
        setShow(false)
        dispatch(requestRevenge())
    }

    const handleBackToOptions = () => {
        setShow(false)
        if (type === AppType.puzzles) {
            dispatch(choosePuzzle(''))
            // navi('..')
        } else {
            dispatch(setGameState({...InitialGameState}))
        }
    }

    const newEvent = () => {
        setShow(false)
        if (!result) {
            const next = (puzzles.findIndex((p: IPuzzle) => p._id === selectedPuzzle) + 1) 
                % (puzzles.length) 
                || 0
            if (puzzles[next]) {
                dispatch(choosePuzzle(puzzles[next]._id))
            }
        } else {
            dispatch(createChallenge(true))
        }
    }

    const btn1_name = result
        ? i18n.gameOptions
        : i18n.backToPuzzMenu

    const btn3_name = result
        ? (vsEngine 
            ? `New vs ${EngineLevels[rivalLevel]}`
            : `New ${gameTimeLimit} / ${adds}`)
        : 'Next puzzle'

    // console.log('event end popup', message, result, puzzleResolved)
    const clName = "event-end-popup noselect" + (puzzleResolved?.animated ? ' puzzle'  : '')
    return  (
        <section className={clName}>
            <header className={`popup-header ${playerWin ? 'win-bg' : 'lose-bg'}`}>
                <h4 className="popup-title">{message}</h4> 
                <LuX onClick={handleClose} />
            </header>
            <div className="popup-body">
                <Ads />
            </div>
            <div className={`popup-footer ${playerWin ? 'win-bg' : 'lose-bg'}`}>
                {vsEngine || puzzleResolved
                    ? null
                    : <button 
                        className="popup-button rematch" 
                        type="button"
                        onClick={handleRematch}
                    >
                        {i18n.rematch}
                    </button>
                }
                <button 
                    className="popup-button new-rival" 
                    type="button"
                    onClick={handleBackToOptions}
                >
                   {btn1_name}
                </button>
                { puzzles.length 
                    ? <button 
                        className="popup-button new-rival" 
                        type="button"
                            onClick={newEvent}
                    >
                        {btn3_name}
                    </button>
                    : null
                }
            </div>
        </section>
    ) 
}
 
export default EventEndPopup
